<template lang="pug">
.brand-themes-template-box.mb-0(@click="$emit('click')" :class="boxClasses" :ref="paletteRef")
  .brand-themes-template-box-overlay
  inline-svg(v-if="img && img.endsWith('.svg')" :src="img")
  img(v-else-if="img" :src="img")
  inline-svg(
    v-else
    :src="require('@/assets/admin/svg/theme-templates/Reykjavik/free-shipping.svg')"
  )
</template>

<script>
  import { get as _get } from 'lodash-es';
  import useCaseRulesMixin from '@/mixins/useCaseRules';
  import paletteMixin from '@/mixins/palette';

  export default {
    mixins: [paletteMixin, useCaseRulesMixin],
    props: {
      template: { type: Object, required: true },
      uniqueColors: {
        type: Boolean,
        default: false,
      },
      color: {
        type: String,
        default: null,
      },
    },
    data: () => ({
      scope: null,
    }),
    computed: {
      img() {
        return _get(
          this.template,
          'thumbnail.0',
          _get(this.template, 'previewUrls.0', _get(this.template, 'previews.0', null)),
        );
      },
      isUniversal() {
        return _get(this.template, 'universal', false);
      },
      templateType() {
        return _get(this.template, 'type');
      },
      templateStyle() {
        return _get(this.template, 'template.style');
      },
      hasWheel() {
        return (
          _get(this.template, 'hasWheel', false) ||
          (this.templateType === 'interstitial' && _get(this.template, 'gamification', false))
        );
      },
      position() {
        return _get(this.template, 'template.style.overlay.position');
      },
      fullscreen() {
        const overlay = _get(this.templateStyle, 'overlay.background.type');
        return (
          (overlay && ['gradient', 'image'].includes(overlay)) ||
          this.templateType === 'interstitial'
        );
      },
      nanobar() {
        return this.templateType === 'nanobar';
      },
      sidebar() {
        return this.templateType === 'sidebar';
      },
      embedded() {
        return this.templateType === 'embedded';
      },
      centeredBottom() {
        return this.position === 8;
      },
      fullHeight() {
        return _get(this.templateStyle, 'overlay.fitToScreen', false) === true;
      },
      boxClasses() {
        return {
          [this.uniqueClass]: true,
          'not-colorable': this.template && !this.isUniversal,
          'fullscreen-template': this.fullscreen,
          'nanobar-template': this.nanobar,
          'sidebar-template': this.sidebar,
          'fullheight-template': this.fullHeight,
          'centered-bottom-template': this.centeredBottom,
          'embedded-template': this.embedded,
          'has-wheel': this.hasWheel,
        };
      },
      uniqueClass() {
        return `template-${this.template._id}`;
      },
    },
    mounted() {
      if (this.uniqueColors && this.color) {
        this.scope = `.${this.uniqueClass}`;
        this.updatePalette(this.color);
      }
    },
  };
</script>

<style lang="sass">
  .brand-themes-template-box
    position: relative
    background: url('~@/assets/admin/svg/template-background.svg') no-repeat center center / contain #E3E5E8
    display: flex
    align-items: center
    justify-content: center
    border-radius: 4px
    border: 1px solid #f7f7f8
    overflow: hidden
    padding: 15px
    &.fallback-shown
      padding-left: 0
      padding-right: 0
    &:nth-child(2)
      margin-right: 0

    &-overlay
      position: absolute
      left: 0
      top: 0
      width: 100%
      height: 100%
      z-index: 0
      border-radius: 4px

    img,
    svg
      position: relative
      width: 80%
      height: 80%
      object-fit: contain
    &.nanobar-template
      align-items: flex-start
      padding: 0
      img,
      svg
        height: auto
        width: 100%
    &.sidebar-template
      align-items: flex-end
      justify-content: flex-start
      img,
      svg
        width: auto
        height: auto
        max-width: 50%
        max-height: 65%
      &.fullheight-template
        img,
        svg
          height: 100%
      &.centered-bottom-template
        svg
          height: 33%
          margin-left: auto
          margin-right: auto
    &.fullscreen-template
      padding: 0
      img, svg
        width: 100%
        height: 100%
        object-fit: cover
    &.has-wheel
      svg
        margin-left: -5vw
    &.embedded-template
      padding: 0
      img, svg
        height: 100%
        width: 100%
        object-fit: cover
</style>

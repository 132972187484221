<template>
  <svg :width="width" :height="height" viewBox="0 0 4 8" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M3.89391 3.97125L0.960529 7.08653C0.819055 7.23678 0.58969 7.23678 0.448232 7.08653L0.106102 6.72319C-0.0351305 6.57319 -0.0354021 6.3301 0.105498 6.17976L2.43025 3.69921L0.105498 1.21868C-0.0354021 1.06833 -0.0351305 0.825243 0.106102 0.675252L0.448232 0.311904C0.589705 0.161657 0.81907 0.161657 0.960529 0.311904L3.89389 3.42718C4.03537 3.57742 4.03537 3.82101 3.89391 3.97125Z"
      :fill="color"
    />
  </svg>
</template>

<script>
  export default {
    props: {
      active: { type: Boolean, default: false },
      activeColor: { type: String, default: '#ED5A29' },
      color: { type: String, default: '#B9BEC6' },
      width: { type: Number, default: 6 },
      height: { type: Number, default: 10 },
    },
  };
</script>

import { translate } from '../../../utils';
import { initMobileStyles } from '../util';

const initStyle = (style) => {
  style = style || {};
  style.border = style.border || {
    selectedBorder: 'no',
    type: 'solid',
    width: 2,
    radius: [3, 3, 3, 3],
    radiusChained: true,
    color: 0,
  };
  style.shadow = style.shadow || { type: 'none', color: '#000' };
  style.labelColor = style.labelColor || '#000';

  return style;
};

const initLabels = (labels) => {
  labels = labels || {};
  labels.days = typeof labels.days === 'string' ? labels.days : translate('days');
  labels.hours = typeof labels.hours === 'string' ? labels.hours : translate('hours');
  labels.minutes = typeof labels.minutes === 'string' ? labels.minutes : translate('minutes');
  labels.seconds = typeof labels.seconds === 'string' ? labels.seconds : translate('seconds');

  return labels;
};

export default (element) => {
  element.style.countdown = initStyle(element.style.countdown);
  element.data.countdown = element.data.countdown || {};
  element.data.countdown.labels = initLabels(element.data.countdown.labels);
  initMobileStyles(element);
  return element;
};

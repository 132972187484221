import { baseUrl } from '@/config/url';

const TYPES = {
  DYNAMIC_CONTENT: 'dynamic_content',
  EMBEDDED: 'embedded_placement',
};

/**
 * @param {Object} data
 * @return {string}
 */
const createURL = (data) => {
  const url = new URL(`${baseUrl}api/web-selector/auth`);

  url.searchParams.append('x-om-data', btoa(JSON.stringify(data)));

  return url.href;
};

/**
 * @param {Object} params
 * @param {string} params.domain
 * @param {number} params.campaignId
 * @param {string} params.variantId
 * @param {string} params.returnUrl
 * @param {string} params.backUrl
 * @param {string} params.locale
 * @param {boolean} [params.isSuperAdmin=false]
 * @param {string} [params.lastEditUrl='']
 * @param {boolean} [params.isNew=false]
 * @param {string} [params.featureMode=null | 'smart-ab-test']
 *
 * @return {string}
 */
export const createDCUrl = (params) => {
  params.isNew = typeof params.isNew !== 'undefined' ? !!params.isNew : false;
  params.lastEditUrl = params.lastEditUrl || '';
  params.isSuperAdmin = !!params.isSuperAdmin;
  return createURL({ ...params, type: TYPES.DYNAMIC_CONTENT });
};

/**
 * @param {Object} params
 * @param {string} params.domain
 * @param {number} params.campaignId
 * @param {string[]} [params.positions]
 * @param {string} [params.returnUrl]
 * @param {string} [params.locale]
 *
 * @return {string}
 */
export const createEmbeddedUrl = (params) => {
  return createURL({ ...params, type: TYPES.EMBEDDED });
};

import { initMobileStyles } from '../util';

export default (element) => {
  element.style.size = element.style.size || 30;

  initMobileStyles(element, ['size']);

  element.style.mobile.size = element.style.mobile.size || 10;

  return element;
};

<template lang="pug">
.template-goals(v-if="goals.length")
  .template-goal-title {{ $t('goalsTitle') }}:
  .template-goals-list {{ goals }}
</template>
<script>
  export default {
    props: {
      template: {
        type: Object,
      },
    },
    computed: {
      goals() {
        if (!this.template) return [];
        if (!this.template.goals) return [];
        return (
          this.template?.goals
            ?.map((goal) => this.$t(`templateFilter.goals.${goal}`))
            ?.join(', ') || ''
        );
      },
    },
  };
</script>
<style lang="sass" scoped>
  @import '@/sass/variables/_colors.sass'
  .template-goal
    &s
      color: $om-gray-700
      line-height: 1.5
      font-size: 1rem
      display: flex
    &s-list
      color: $om-gray-800
      font-weight: 500
      padding-left: .25rem
</style>

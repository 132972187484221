/**
 * @param {string} input
 * @param {string} campaignDomain
 * @return {string}
 */
const getRedirectDomain = (input, campaignDomain) => {
  if (!input) return campaignDomain;

  const rawUrl = /^(https?:\/\/|\/\/)/.test(input) ? input : `http://${input}`;
  const originalUrl = new URL(rawUrl);

  return originalUrl.hostname ?? campaignDomain;
};

export { getRedirectDomain };

const _clone = (v) => JSON.parse(JSON.stringify(v));

export const closeGesturesDef = {
  onEsc: true,
  onOverlayClick: false,
  onOverlayClickDevice: 'mobile',
};

export default (data) => {
  data = data || {};

  data.closeGestures = data.closeGestures || _clone(closeGesturesDef);

  return data;
};

import { tacticLibraryUrl, slugify } from '@/utils/tactics';
import { track } from '@/services/xray';

export default {
  props: {
    learnMoreOpenUrl: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    hasImage() {
      return !!this.tactic?.svgPreviews?.[0];
    },
    image() {
      return this.tactic.svgPreviews[0];
    },
    getItemNameForTracking() {
      if (this.tactic.content) {
        const url = this.tactic.content;
        const parts = url.split('/');
        return parts[parts.length - 2];
      }
      return '';
    },
  },
  methods: {
    usageText(tactic) {
      const fullText = tactic.usage[this.$i18n.locale];
      const plainText = fullText.replace(/<[^>]+>/g, '');
      if (plainText.length <= this.targetingCharacterLimit) return plainText;
      const limitedText = plainText.substring(0, this.targetingCharacterLimit - 4);
      return `${limitedText} ...`;
    },
    learnMore(tactic, trackId) {
      track(trackId, { name: tactic.name.en });
      if (this.learnMoreOpenUrl) {
        const tacticName = tactic.name[this.$i18n.locale];
        const link = `${tacticLibraryUrl[this.$i18n.locale]}/${slugify(tacticName)}-${tactic._id}`;
        window.open(link, '_blank');
      } else {
        this.$emit('click', tactic);
      }
    },
  },
};

<template lang="pug">
.template-prev-page-previews(:class="{ 'overflow-scrolling-touch': pages.length > 3 }")
  .template-prev-page-preview(
    v-for="(page, index) in templateBasedPages"
    :class="{ 'template-prev-page-preview-selected': selectedPageUid === page.uid, 'template-prev-page-preview-teaser': page.isTeaser }"
    @click="selectPage(page)"
    :data-track-property="`eventName:template_preview|location:main|component:page_navigation|item:${page.data.title}`"
  )
    preview-frame(
      :load="bigPreviewLoaded"
      :key="page.uid"
      :selectedPageUid="page.uid"
      :template="template"
      device="desktop"
      small
      :color="color"
      :themeKit="themeKit"
      :isAdmin="isAdmin"
    )
    .template-prev-separator.d-flex.align-items-center(v-if="isRightChevronSvgVisible(index)")
      RightChevronSvg
    .template-prev-separator.d-flex.align-items-center(
      v-if="!isEmbedded && isCircleSvgVisible(page, index)"
    )
      CircleSvg
    .template-prev-title(:class="{ 'template-prev-title-teaser': page.isTeaser }") {{ page.data.title }}
  template(v-if="!isUniversal && !isEmbedded && !teaserPage")
    .template-prev-page-preview(
      :class="{ 'template-prev-page-preview-selected': selectedPageUid === 'tab' }"
      @click="selectPage('tab')"
    )
      preview-frame(
        :load="bigPreviewLoaded"
        :template="template"
        selectedPageUid="tab"
        device="desktop"
        small
        :color="color"
        :themeKit="themeKit"
        :isAdmin="isAdmin"
      )
      .template-prev-title.template-prev-title-teaser {{ $t('tab') }}
</template>
<script>
  import PreviewFrame from '@/components/Modals/TemplatePreview/PreviewFrame';
  import RightChevronSvg from '@/assets/admin/svg/templatePreview/RightChevronSvg';
  import CircleSvg from '@/assets/admin/svg/templatePreview/CircleSvg';

  export default {
    components: { PreviewFrame, RightChevronSvg, CircleSvg },
    props: {
      pages: {
        type: Array,
      },
      bigPreviewLoaded: {
        type: Boolean,
        default: false,
      },
      template: {
        type: Object,
      },
      color: {
        type: String,
      },
      themeKit: {
        type: Object,
      },
      isAdmin: {
        type: Boolean,
      },
      selectedPageUid: {
        type: String,
      },
    },
    computed: {
      teaserPage() {
        return this.pages.find((page) => page.isTeaser);
      },
      normalPages() {
        return this.pages.filter((page) => !page.isTeaser);
      },
      isEmbedded() {
        return this.template?.type === 'embedded';
      },
      isUniversal() {
        return !!this.template?.universal;
      },
      templateBasedPages() {
        return this.isEmbedded ? this.normalPages : this.pages;
      },
      isNewTeaser() {
        return this.isUniversal || this.teaserPage;
      },
    },
    methods: {
      selectPage(page) {
        this.$emit('page-change', page?.uid || page);
      },
      isRightChevronSvgVisible(index) {
        return this.pages[index + 1] && !this.pages[index + 1].isTeaser;
      },
      isNextPageIsTeaser(page, index) {
        return !page.isTeaser && this.pages[index + 1] && this.pages[index + 1].isTeaser;
      },
      isOldPageTeaser(index) {
        return index === this.pages.length - 1;
      },
      isCircleSvgVisible(page, index) {
        return this.isNewTeaser
          ? this.isNextPageIsTeaser(page, index)
          : this.isOldPageTeaser(index);
      },
    },
  };
</script>

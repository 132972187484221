import { getPageName, isDefined } from '@/editor/util';
import { DefaultLayout as DefaultMockLayout } from '@/editor/components/MockSite.vue';
import initializers from '@om/editor-ssr-shared/src/core/initializers/index';
import elementInitializers from '@om/editor-ssr-shared/src/core/initializers/elements/index';
import { initMobileStyles } from '@om/editor-ssr-shared/src/core/initializers/util';

class NoBoxError extends Error {
  constructor() {
    super('No boxes in page');
  }
}

const _i18n = () => (typeof window === 'undefined' ? { t: () => '' } : window.parent.om.i18n);

const fixInvalidSettings = (element, template) => {
  if (element.style && element.style.background) {
    const { color, type } = element.style.background;
    const { type: globalType } =
      (template.style[element.data.type] && template.style[element.data.type].background) || {};

    if (
      element.data.type === 'inputs' &&
      type === 'solid-fill' &&
      color === null &&
      globalType === 'transparent'
    ) {
      element.style.background.type = 'transparent';
    }
  }

  if (element.style && element.style.shadow) {
    const { color, type } = element.style.shadow;
    const { type: globalType, color: globalColor } =
      (template.style[element.data.type] && template.style[element.data.type].shadow) || {};

    if (element.data.type === 'inputs' && type === null && globalType) {
      element.style.shadow.type = globalType;
      element.style.shadow.color = globalColor || color;
    }
  }
};

const migrateFourValue = (element, key) => {
  if (element.style && element.style[key]) {
    const { top, bottom, left, right, allSides } = element.style[key];
    const isPadding = key.indexOf('Padding') !== -1;

    if (isPadding) {
      if (allSides) {
        const allValue = element.style[key].padding;

        if (isDefined(allValue)) {
          element.style[key].top = allValue;
          element.style[key].bottom = allValue;
          element.style[key].left = allValue;
          element.style[key].right = allValue;
        }
      }

      delete element.style[key].padding;
    }

    if (
      !allSides &&
      isDefined(top) &&
      isDefined(bottom) &&
      isDefined(left) &&
      isDefined(right) &&
      top === bottom &&
      bottom === left &&
      left === right
    ) {
      element.style[key].allSides = true;
    }
  }
};

const migrateFourValues = (element) => {
  migrateFourValue(element, 'manualPadding');
  migrateFourValue(element, 'mobilePadding');
  migrateFourValue(element, 'mobile.manualPadding');
  migrateFourValue(element, 'manualMargin');
  migrateFourValue(element, 'mobile.manualMargin');
};

const pageInitializer = (page, index, pagesLength) => {
  page.data = page.data || {};
  page.data.title =
    page.data.title ||
    (index === 0
      ? _i18n().t('mainPage')
      : index === pagesLength - 1
      ? _i18n().t('thankYou')
      : getPageName(index));
  // =========================================================
  // -----> MOVED TO v2 ELEMENT INITIALIZERS
  // =========================================================
  // page.style = page.style || {}
  // if (_get(template, 'style.mode') === 'embedded') {
  //   page.style.verticalAlign = page.style.verticalAlign || 'flex-start'
  // }
  // page.style.horizontalAlign = page.style.horizontalAlign || 'flex-start'
  // page.style.canvas = page.style.canvas || {}
  // page.style.canvas.contentWidthMode = page.style.canvas.contentWidthMode || 'full'
  // page.style.canvas.maxWidth = page.style.canvas.maxWidth || 250
  // page.style.canvas.overflow = page.style.canvas.overflow || 'default'
  migrateFourValues(page);
};

const boxInitializer = (box) => {
  migrateFourValues(box);
  initMobileStyles(box, ['background', 'manualMargin']);
};

const columnInitializer = () => {
  // =========================================================
  // -----> MOVED TO v2 ELEMENT INITIALIZERS
  // =========================================================
  // column.style = column.style || {}
  // column.style.verticalAlign = column.style.verticalAlign || 'flex-start'
  // migrateFourValues(column)
  // initMobileStyles(column, ['background', 'manualMargin'])
};

const elementInitializer = (element, template, whiteLabel) => {
  let elementType = element.type.toLowerCase().slice(2);

  if (['textarea', 'input', 'radio', 'dropdown', 'checkbox'].includes(elementType)) {
    elementType = 'inputs';
  }

  migrateFourValues(element);

  elementInitializers.common(element, template, whiteLabel);

  if (elementInitializers[elementType]) {
    return elementInitializers[elementType](element, template, whiteLabel);
  }
};

const templateInitializer = (template, whiteLabelData) => {
  delete template['style.data.tabText'];

  const keys = Object.keys(template);
  const initKeys = Object.keys(initializers);
  keys.forEach((_, i) => {
    const key = keys[i];
    if (initializers[key]) {
      template[key] = initializers[key](template[key]);
    }
  });

  initKeys.forEach((initKey) => {
    if (!template[initKey]) {
      template[initKey] = initializers[initKey](template[initKey]);
    }
  });

  template.pages.forEach((p, i) => {
    pageInitializer(p, i, template.pages.length, template);
    if (!p.boxes) {
      throw new NoBoxError();
    }

    p.boxes.forEach((b) => {
      boxInitializer(b, template);
      b.columns.forEach((c) => {
        columnInitializer(c, template);
        c.elements.forEach((e) => {
          elementInitializer(e, template, whiteLabelData);

          fixInvalidSettings(e, template);
        });
      });
    });
  });

  template.mockSiteLayout = template.mockSiteLayout || DefaultMockLayout;

  return template;
};

export { templateInitializer, elementInitializer, pageInitializer };

import { _pixelToEm } from '@/editor/services/css/v2/helpers';

export function calculatePageHeight(pageEl, pageStyle, emBased = true) {
  // TODO: remove dead code after QA
  // const contentEl = pageEl.querySelector(`.om-canvas-content`);
  if (!pageEl.style.minHeight) pageEl.style.height = 'max-content';
  let height = 0;

  const { marginTop, marginBottom } = window.getComputedStyle(pageEl);
  const verticalMargin = parseInt(marginTop, 10) + parseInt(marginBottom, 10) || 0;

  height = pageEl.getBoundingClientRect().height + verticalMargin;

  return { height, unit: 'px' };
  /*
  let pagePadding = 0;
  let border = 0;
  if (!contentEl) {
    height = pageEl.getBoundingClientRect().height;
  } else {
    height = contentEl.getBoundingClientRect().height;
    console.log('height', height);

    const { padding, manualPadding, border: borderStyle, minHeight } = pageStyle || {};
    if (padding) {
      pagePadding += parseInt(padding.top || 0, 10) + parseInt(padding.bottom || 0, 10);
    } else if (manualPadding) {
      pagePadding += parseInt(manualPadding.top || 0, 10) + parseInt(manualPadding.bottom || 0, 10);
    }
    if (borderStyle) {
      const { width = 0, selectedBorder = 'no' } = borderStyle;
      if (selectedBorder === 'top' || selectedBorder === 'bottom') {
        border += width || 0;
      } else if (selectedBorder === 'top-bottom' || selectedBorder === 'full') {
        border += 2 * (width || 0);
      }
    }

    if (minHeight && !emBased) {
      return { height: _pixelToEm(border), unit: 'rem' };
    }

    if (emBased) {
      // Convert it to 16px based height (needed for pxToEm)
      const computedStyle = window.getComputedStyle(pageEl);
      height = (height / parseInt(computedStyle.getPropertyValue('font-size'), 10)) * 16;
    }
    height += pagePadding + border;
  }

  return { height, unit: 'px' };
  */
}

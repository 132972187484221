import { mapGetters } from 'vuex';

export default {
  computed: {
    ...mapGetters(['isFreemium']),
    currentUseCase() {
      return this.useCase || (this.template && this.template.useCase) || {};
    },
  },
};

import { ELEMENTS } from '@om/template-properties/src/propertyHelper';
import { get as _get, set as _set } from 'lodash-es';
import { initFeedbackOptions } from '../utils';

const _migrateBorderRadiusValue = (type, value) => {
  // clockwise       tl    tr    br    bl
  const migrated = [null, null, null, null];
  if (!type || type === 'none' || !value) return migrated;

  if (type === 'full') {
    return migrated.map(() => value);
  }
  if (type === 'top') {
    return [value, value, 0, 0];
  }
  if (type === 'bottom') {
    return [0, 0, value, value];
  }
  if (type === 'left') {
    return [value, 0, 0, value];
  }
  if (type === 'right') {
    return [0, value, value, 0];
  }

  return migrated;
};

export const _migrateBorderRadius = (element, device) => {
  const path = device ? `${device}.border.radius` : `radius`;
  const radiusType = _get(element, `${path}Type`, false);
  const radius = _get(element, path, false);

  if (!Array.isArray(radius) && (radiusType || radiusType === null)) {
    if (device) {
      delete element[device].border.radiusType;
    }
    delete element.radiusType;
    const value = _get(element, path, false);
    const migrated = _migrateBorderRadiusValue(radiusType, value);

    _set(element, path, migrated);
    _set(element, `${path}Chained`, radiusType === 'full');
  }
};

const _overrideOptions = ({ element, type, options, i18n, optionPath, isSSR }) => {
  if (type === ELEMENTS.OmFeedback) {
    const feedbackOptions = initFeedbackOptions(element.data.displayType, isSSR);
    _set(element, optionPath, feedbackOptions);
  } else {
    options.forEach(({ key }, index) => {
      const translatedOption = i18n().t('myOption1', { num: 1 });
      const isCorrectTranslatedOption = translatedOption === key;
      if (isCorrectTranslatedOption) return;

      _set(element, `${optionPath}[${index}].key`, translatedOption);
    });
  }
};

export const migrateOptionsText = (element, type, i18n, isSSR) => {
  if (
    ![
      ELEMENTS.OmRadio,
      ELEMENTS.OmCheckbox,
      ELEMENTS.OmFeedback,
      ELEMENTS.OmSurvey,
      ELEMENTS.OmDropdown,
    ].includes(type) ||
    isSSR
  )
    return;

  const optionPath = 'data.form.customSettings.options';
  const options = _get(element, optionPath);

  _overrideOptions({ element, type, options, i18n, optionPath, isSSR });
};

const base = ({ element, template }) => {
  if (element.subElements) {
    Object.keys(element.subElements).forEach((type) =>
      base({ template, element: element.subElements[type] }),
    );
  }

  _migrateBorderRadius(element, 'desktop');
  _migrateBorderRadius(element, 'mobile');
};

export default base;

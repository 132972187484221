<template lang="pug">
.brand-template-progress-bar.py-3
  .mr-auto(v-if="isInPreview" @click="$modal.hide('template-preview')") {{ $t('backToGallery') }}
  progress-items
  button.brand-btn.brand-btn-primary.ml-auto(v-if="isInPreview" @click="select") {{ $t('useTemplate') }}
</template>
<script>
  import ProgressItems from './ProgressItems.vue';

  export default {
    components: { ProgressItems },

    props: {
      template: {
        type: Object,
        default: null,
      },
    },

    computed: {
      isInPreview() {
        return this.template;
      },
    },

    methods: {
      select() {
        this.$modal.hide('template-preview');
        this.$modal.show('name-campaign', { templateId: this.template._id });
      },
    },
  };
</script>

<style lang="sass">
  .brand-template-progress-bar
    background: white
    font-size: 0.75rem
    text-transform: uppercase
    font-weight: bold
    display: flex
    justify-content: center
    align-items: center
    box-shadow: 0px 10px 30px rgba(9, 46, 117, 0.07)
    color: #ED5A29

  @media screen and (max-width: 768px)
    .brand-template-progress-bar
      display: none !important
</style>

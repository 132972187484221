import { isWhitelabelDomain } from './url';
import runtimeConfig from './runtime';

const { VUE_APP_CDN_URL, VUE_APP_WHITELABEL_CDN_URL } = runtimeConfig;

const getCdnUrl = () => {
  return isWhitelabelDomain() ? VUE_APP_WHITELABEL_CDN_URL : VUE_APP_CDN_URL;
};

export { getCdnUrl };

<template lang="pug">
om-modal(
  ref="templatePreview"
  :modalClasses="`template-preview-modal`"
  name="template-preview"
  width="100%"
  color="light"
  @beforeOpen="beforeOpen"
  @beforeClose="beforeClose"
  transition="fadeRight"
  overlayTransition="fade"
  :scrollable="true"
  data-track-property="eventName:admin_click|location:template-preview|setting:{event}"
)
  template(slot="modal-header")
    .template-preview-close.cursor-pointer(@click="$modal.hide('template-preview')")
      close-icon(:width="16" :height="16" color="#B9BEC6")
  template(slot="modal-body")
    .flex.flex-col(v-if="isBelowLargeSize")
      .flex.flex-row(style="justify-content: space-between")
        om-heading(
          :class="{ 'has-content': !loading && template && previewTitle }"
          v-if="(!loading && previewTitle) || !template"
          h3
        )
          span(v-if="!loading && template") {{ previewTitle }}
        om-button#template-preview-use-template(
          primary
          large
          :loading="inWizard && campaignCreationLoading"
          @click="getStartedDebounced"
          data-track-property="eventName:template_preview|location:main|component:cta_button"
        )
          span {{ $t('selectTemplate') }}
      .campaign-sm-details
        conversion-rate.my-auto(v-if="!loading && template" :template="template")
        goals.my-auto.ml-4(:template="template")
    .template-preview-body.row
      .col-12.col-xl-12.col-xxl-9.col-xxxl-9.col-custom
        previews(:template="template" :themeKit="correctThemeKit" :isAdmin="true" ref="previews")
      .col.px-0(v-if="!isBelowLargeSize")
        .template-preview-sidebar.ml-3
          om-heading.template-preview-name(
            :class="{ 'has-content': !loading && template && previewTitle }"
            v-if="(!loading && previewTitle) || !template"
            h3
          )
            span(v-if="!loading && template") {{ previewTitle }}
          .template-actions
            om-button#template-preview-use-template(
              primary
              large
              block
              :loading="inWizard && campaignCreationLoading"
              @click="getStartedDebounced"
              data-track-property="eventName:template_preview|location:main|component:cta_button"
            )
              span {{ $t('selectTemplate') }}
          om-body-text.pt-4.pb-4(v-if="!loading && tacticHeadline" bt400md v-html="tacticHeadline")

          goals.pt-2(:template="template")
          template(v-if="theme && isUniversal")
            .template-preview-color-picker.mt-5
              .template-preview-color-picker-title {{ $t('changeColor') }}
              om-color-picker.ml-auto(v-model="color")
            .template-preview-palette
              om-color-palette.mb-0(@selected="color = $event")
    .template-description(v-if="isBelowLargeSize")
      om-body-text(v-if="!loading && tacticHeadline" bt400lg v-html="tacticHeadline")
      om-link.mt-3(
        primary
        v-if="!loading && showTacticLink"
        @click="learnMore(useCase, 'template-preview-learnMore')"
        :data-track-property="`eventName:template_preview|location:main|component:learn_more|item:${useCase.name.en}`"
      ) {{ $t('templateChooser.learnMore') }}
        UilExternalLinkAlt.ml-1
    .my-5(v-if="useCaseTargeting")
      om-heading(h3) {{ $t('previewModal.whenToUseIt') }}
      om-body-text.mt-3(bt400lg style="color: #505763")
        span(v-html="useCaseTargeting")
    .row
      template-slider(
        v-if="!loading && template && otherTemplatesInUseCase"
        :title="$t('previewModal.sliders.moreFromTactics')"
        :color="color"
        :customThemes="customThemes"
        :templates="otherTemplatesInUseCase"
        @change-template="setInitials({ ...$event, initialColor: color, device, heapTracking })"
        idPostfix="more"
        data-track-property="eventName:template_preview|location:main|component:tactic_click|item:more_tactic"
      )
      template-slider(
        v-if="!loading && template && otherTemplatesInTheme.length"
        :title="$t('previewModal.sliders.moreFromTheme')"
        :color="color"
        :themeKit="correctThemeKit"
        :templates="otherTemplatesInTheme"
        @change-template="setInitials({ ...$event, initialColor: color, device, heapTracking })"
        idPostfix="theme"
        data-track-property="eventName:template_preview|location:main|component:template_click|item:template_open"
      )
      .col-12.om-template-preview-advanced-tactic(data-track-property="component:advanced-tactics")
        advanced-tactics-recommendation(
          :limit="advancedTacticsLimit"
          isSlider
          data-track-property="eventName:template_preview|location:main|component:tactic_click|item:suggested_tactic"
        )
          template(slot="advanced-tactics-recommendation-header")
            .row
              .col-12
                om-heading(h3) {{ $t('previewModal.sliders.advancedTactics') }}
          template(slot="advanced-tactics-recommendation-card" slot-scope="{ tactic }")
            advanced-tactic-card-minimal(:index="tactic.index" :tactic="tactic.data")
  new-campaign
</template>

<script>
  import NewCampaign from '@/components/Modals/NewCampaign.vue';
  import ConversionRate from '@/components/Modals/TemplatePreview/ConversionRate';
  import Goals from '@/components/Modals/TemplatePreview/Goals';
  import Previews from '@/components/Modals/TemplatePreview/Previews';
  import { BasicTemplateCard, TemplateProgressBar } from '@/components/Template/';
  import TemplateSlider from '@/components/Template/TemplateSlider';
  import AdvancedTacticsRecommendation from '@/components/Tactics/AdvancedTacticsRecommendation.vue';
  import AdvancedTacticCardMinimal from '@/components/Tactics/AdvancedTacticCardMinimal.vue';
  import { LIBRARY_STORE_KEY } from '@/config/constants';
  import MockSite, {
    DefaultLayout as DefaultMockLayout,
    MockLayouts,
  } from '@/editor/components/MockSite.vue';
  import GET_CUSTOM_THEMES from '@/graphql/GetCustomThemes.gql';
  import GET_SIMILAR_TEMPLATES from '@/graphql/GetSimilarTemplates.gql';
  import GET_TEMPLATE from '@/graphql/GetTemplate.gql';
  import GET_TEMPLATE_THEME from '@/graphql/GetTemplateTheme.gql';
  import GET_TACTIC_BASE_DATA from '@/graphql/GetTacticBaseData.gql';
  import { lsStore } from '@/helpers/localStorage';
  import { createCampaignAndRedirectToEditor } from '@/router/utils';
  import { track } from '@/services/xray';
  import wizardSharedMixin from '@/views/Wizard/shared';
  import tacticsMixin from '@/components/Tactics/tacticsMixin';
  import { getPaletteColors } from '@om/template-properties/src/getPaletteColors';
  import { debounce } from 'lodash-es';
  import { mapGetters, mapState } from 'vuex';
  import { UilExternalLinkAlt } from '@iconscout/vue-unicons';

  export default {
    components: {
      NewCampaign,
      BasicTemplateCard,
      TemplateProgressBar,
      MockSite,
      ConversionRate,
      TemplateSlider,
      Goals,
      Previews,
      AdvancedTacticsRecommendation,
      AdvancedTacticCardMinimal,
      UilExternalLinkAlt,
    },
    filters: {
      removeLocal(value) {
        return value.replace('_hu', '');
      },
    },

    mixins: [wizardSharedMixin, tacticsMixin],

    apollo: {
      customThemes: {
        query: GET_CUSTOM_THEMES,
        update({ themes }) {
          return themes;
        },
      },
    },

    data() {
      return {
        MockLayouts,
        templateId: null,
        template: null,
        device: 'desktop',
        mockSiteLayout: DefaultMockLayout,
        templatePages: [],
        maxHeightPageIndex: null,
        theme: false,
        color: null,
        colorChanged: false,
        loading: false,
        campaignCreationLoading: false,
        heapTracking: false,
        useCase: {},
        useCaseName: null,
        useCaseTargeting: null,
        tacticHeadline: null,
        otherTemplatesInTheme: [],
        themeKit: null,
        customThemes: null,
        advancedTacticsLimit: 7,
        windowWidth: window.innerWidth,
      };
    },

    computed: {
      ...mapState(['locale']),
      ...mapGetters(['wizardPreferences']),

      isBelowLargeSize() {
        return this.windowWidth <= 1280;
      },
      isSimilarTemplatesPage() {
        return this.$route.path.includes('similar-templates');
      },
      isUniversal() {
        return this.template?.universal;
      },
      templateName() {
        return this.template?.displayName?.length
          ? this.template?.displayName
          : this.template?.name?.replace?.('_hu', '');
      },
      inWizard() {
        return this.$route.name.includes('wizard');
      },
      otherTemplatesInUseCase() {
        return this.useCase?.templates?.filter(({ _id, universal, template }) => {
          if (_id === this.template?._id) return false;
          if (!universal) return true;
          return template?.themeKit?.name;
        });
      },
      previewTitle() {
        return !this.isUniversal ? this.templateName : this.useCaseName;
      },
      isCustomThemeOpened() {
        return this.themeKit?.source && this.themeKit?.name !== this.themeKit?.source;
      },
      themeKitByTemplate() {
        if (!this.template || !this.isUniversal || !this.template?.template.includes('themeKit')) {
          return undefined;
        }
        const customThemeType = this.isCustomThemeOpened ? 'custom' : 'base';
        const theme = this.customThemes[customThemeType].find(({ themeKit }) => {
          return this.isCustomThemeOpened
            ? this.themeKit?.id === themeKit?.id
            : themeKit?.source === this.template?.theme;
        });
        return theme?.themeKit;
      },
      correctThemeKit() {
        return this.themeKit ?? this.themeKitByTemplate;
      },
      showTacticLink() {
        return this.useCase.name && this.useCase._id;
      },
    },

    watch: {
      color(current, prev) {
        if (current && current !== prev && !this.colorChanged) this.colorChanged = true;
      },
    },

    created() {
      this.getStartedDebounced = debounce(this.getStarted, 500);
    },

    mounted() {
      window.addEventListener('resize', this.onResize);
    },

    beforeDestroy() {
      window.removeEventListener('resize', this.onResize);
    },

    methods: {
      async getUseCaseNameAndTargeting() {
        this.useCaseName = null;
        this.useCaseTargeting = null;
        const {
          data: { useCase },
        } = await this.$apollo.query({
          query: GET_TACTIC_BASE_DATA,
          variables: {
            id: this.template?.useCase,
          },
        });
        this.useCaseName = useCase?.name?.[this.$i18n.locale] || null;
        this.useCaseTargeting = useCase?.targeting?.[this.$i18n.locale] || null;
        this.tacticHeadline = useCase?.usage[this.$i18n.locale] || null;
      },
      async fetchTemplate() {
        this.loading = true;
        try {
          const {
            data: { template },
          } = await this.$apollo.query({
            query: GET_TEMPLATE,
            variables: {
              input: {
                _id: this?.templateId,
              },
            },
          });
          this.template = template;

          if (this.template && this.template.universal) {
            await this.getUseCaseNameAndTargeting();
          }

          this.$refs.templatePreview.$el.scrollTop = 0;
        } catch (e) {
          console.error(`Cannot fetch template (${this.templateId})`, e);
        }
        this.loading = false;
      },
      async setInitials(payload) {
        const { device, templateId, initialColor, heapTracking, themeKit } = payload;
        this.device = device;

        this.themeKit = themeKit;
        this.templateId = templateId;
        this.theme = null;
        this.heapTracking = heapTracking || false;
        this.color = initialColor;

        let themeColor = initialColor;
        if (!initialColor && this.theme) {
          themeColor = await this.getThemeColor(this.theme);
        }
        this.template = null;
        this.useCaseName = null;
        await this.fetchTemplate();
        const [useCase, otherTemplatesInTheme] = await Promise.all([
          this.fetchUseCaseTemplates(),
          this.fetchThemeTemplates(),
        ]);
        this.useCase = useCase;
        this.otherTemplatesInTheme = otherTemplatesInTheme;
        this.color = this.template?.universal ? themeColor : null;
      },
      async beforeOpen(event, device = 'desktop') {
        const themeKit = this.themeKit || event.params.themeKit || null;
        const templateId = this.$route.params.templateId || event.params.templateId;
        const initialColor = null;
        const theme = null;

        await this.setInitials({
          device,
          templateId,
          theme,
          heapTracking: event.params.heapTracking || false,
          initialColor,
          themeKit,
        });
      },
      beforeClose() {
        this.themeKit = null;
        this.templateId = null;
        this.theme = null;
        this.heapTracking = false;
        this.color = null;
      },
      async getThemeColor(name) {
        try {
          const {
            data: { theme },
          } = await this.$apollo.query({
            query: GET_TEMPLATE_THEME,
            variables: { name },
          });
          return theme.color;
        } catch (e) {
          console.log(`cannot fallback to theme (${this.theme}) color`, e);
        }
        return null;
      },

      async fetchUseCaseTemplates() {
        if (!this.template.useCase) {
          return [];
        }

        try {
          const {
            data: { useCase },
          } = await this.$apollo.query({
            query: GET_SIMILAR_TEMPLATES,
            variables: {
              id: this.template.useCase,
            },
          });

          return useCase;
        } catch (error) {
          console.log(`template not have have useCase \n`, error);
        }
        return [];
      },

      fetchCustomThemeTemplates() {
        const theme = this.customThemes.base.find(({ name }) => name === this.template?.theme);
        const templates = theme?.templates || [];
        return templates.filter(({ _id }) => _id !== this.template._id);
      },

      async fetchThemeTemplates() {
        const templates = this.fetchCustomThemeTemplates();
        return templates;
      },

      trackHeap() {
        if (this.heapTracking) {
          const type = this.template?.type ? { type: this.template.type } : {};
          track(this.heapTracking.name, {
            ...(this.heapTracking.data || {}),
            ...type,
          });
        }
      },

      async getStarted() {
        if (!this.campaignCreationLoading) {
          this.campaignCreationLoading = true;

          if (this.inWizard) {
            await this.$store.dispatch('finishOnboarding');

            lsStore(LIBRARY_STORE_KEY, {
              templateId: this.template._id,
              color: this.color,
            });

            let gqlVariables = { templateId: this.template._id };

            if (this.isSimilarTemplatesPage) {
              gqlVariables = {
                ...gqlVariables,
                color: null,
                coupon: null,
                style: null,
                goal: null,
                list: null,
                useCases: null,
              };
            }
            this.trackHeap();
            await this.updateWizardPreferences(gqlVariables);
            await createCampaignAndRedirectToEditor('wizard', {
              theme: this.correctThemeKit?.id,
            });
          } else {
            this.$nextTick(() => {
              const { color } = this;
              this.trackHeap();
              const nameCampaignParams = {
                templateId: this.template._id,
                colors: color ? getPaletteColors(color) : undefined,
                colorChange: this.colorChanged,
                theme: this.correctThemeKit?.id,
              };
              this.$modal.hide('template-preview');
              this.$modal.show('name-campaign', nameCampaignParams);
            });
          }

          this.campaignCreationLoading = false;
        }
      },
      onResize: debounce(
        function () {
          this.windowWidth = window.innerWidth;
          this.$refs.previews.init();
        },
        50,
        { trailing: true },
      ),
    },
  };
</script>

<style lang="sass">
  @import '@/sass/variables/variables'
  @import "@/sass/helpers/media-breakpoint"
  .desktop-preview
    .has-wheel
      iframe
        width: 991px
  .template-preview-color-picker
    display: flex
    align-items: center
    color: $om-gray-700
    &-title
      font-size: 0.85rem
    @media (max-width: 1600px)
      .brand-color-picker-input
        width: 12.75rem
    .brand-color-picker-input input
      color: $om-gray-700
    .brand-color-picker-input-color
      flex: 0 0 3.75rem
      border-radius: 0  4px 4px 0
  .template-preview
    &-name
      min-height: 4.55rem
      &.has-content
        min-height: auto
    &-palette
      .brand-color-palette
        margin-left: -10px
        margin-right: -10px
        margin-bottom: -12px!important
        .brand-color-box
          width: 21px
          height: 20px
          margin-left: 8px
          margin-right: 8px
          margin-bottom: 12px
    &-modal
      @import '@/editor/sass/mobile_frame.sass'
      overflow: visible !important
      width: 100% !important
      background: white
      @include media-breakpoint-up(md)
        width: 97% !important
        max-width: 1400px
        margin-top: 20px
        margin-left: auto
        margin-right: auto
        margin-bottom: 102px !important
        border-radius: 20px !important
      .campaign-sm-details
        display: flex
        flex-direction: row
        margin-bottom: 20px
        #template-preview-use-template
          margin-left: auto
      .template-preview-close
        position: absolute
        right: 10px
        top: 5px
        @include media-breakpoint-up(md)
          top: .5rem
          right: 1rem
        &:hover
          opacity: .7
      .om-workspace
        height: 100%
        position: relative
        &-content
          height: 100%
        &-inner
          height: 100%
          position: relative
      .template-preview-pages
        margin-left: -.625rem
        margin-right: -.625rem
        .slide-indicator
          padding-top: 0
          padding-bottom: 0
      .brand-modal
        border-radius: 20px
        &-header
          display: flex
          box-shadow: unset !important
          color: white !important
          z-index: 10
          border-radius: 20px
          padding: 1.25rem 2.857rem
          .slide-indicator
            color: white
        &-body
          padding: 0 2.5rem 0 !important
          padding-bottom: 2.5rem !important
          display: flex
          flex-direction: column
        &-footer
          padding: 0 !important
          margin: 0 !important
      .om-editor-page-controller-title
        padding-top: 0.875rem
        padding-bottom: 0.875rem !important
        transition: 0.5s
        line-height: 1.45
        svg > path
            transition: 0.5s
        &:hover
          color: #EB5A29
          svg > path
            fill: #EB5A29
      .om-editor-page-controller
        i.fa-chevron-right
          padding-bottom: 0
  .template-description
    margin-top: 40px
  .template-preview
    &-footer
      display: flex
      flex-grow: 0
      margin-top: 0.75rem
    &-body
      display: flex
      flex-grow: 1
      .col-custom
        @media (min-width: 1281px)
          max-width: 75%
          flex: 0 0 75%
    &-control-bar
      height: 100%
      .template-preview-devices-selector.embedded svg
        width: 1.25rem
        height: 1.25rem
    &-close
      position: absolute
      top: 1rem
      right: 1.25rem
    &-devices
      flex-grow: 0
      color: #828282
      margin-bottom: 2.25rem
      &-selector
        &.embedded
          &:hover,
          &.pressed
            svg > path
              fill: #EB5A29 !important
          svg
            width: 1rem
            height: auto
        &:hover
          color: rgba(235, 90, 41, 0.3)
          svg > path
            stroke: rgba(235, 90, 41, 0.3)
        &.pressed
          transition: 0.5s cubic-bezier(0.27, 1.64, 0.32, 0.95)
          svg > path
            transition: 0.5s cubic-bezier(0.27, 1.64, 0.32, 0.95)
            stroke: #EB5A29 !important
    &-name
      color: #4F4F4F
      margin-bottom: 2rem
  @media screen and (max-width: 767px)
    .v--modal-overlay
      .template-preview-modal.v--modal-box
        width: 100% !important

  .om-template-preview-advanced-tactic
    .advanced-tactics-cards-horizontal
      padding: 16px 0 40px
</style>

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"template-filter-agency d-flex mt-2 mb-5 row"},[(_vm.showTypeFilter)?_c('div',{staticClass:"template-filter-agency-users",class:_vm.filterColClass},[_c('multiselect',{attrs:{"id":"template-kind","options":_vm.templateKindOptions,"label":"key","track-by":"value","placeholder":_vm.$t('templateManager.placeholders.templateKind'),"multiple":false,"show-labels":false,"allow-empty":false},model:{value:(_vm.templateKindComputed),callback:function ($$v) {_vm.templateKindComputed=$$v},expression:"templateKindComputed"}})],1):_vm._e(),_c('div',{staticClass:"template-filter-agency-types mb-2 mb-md-0",class:_vm.filterColClass},[_c('multiselect',{attrs:{"id":"types","options":_vm.sortedTemplateTypes,"label":"key","track-by":"value","placeholder":_vm.$t('templateManager.selectWidgetType'),"multiple":false,"show-labels":false,"allow-empty":false},model:{value:(_vm.selectedTypeComputed),callback:function ($$v) {_vm.selectedTypeComputed=$$v},expression:"selectedTypeComputed"}})],1),_c('div',{staticClass:"template-filter-agency-goals mb-2 mb-md-0",class:_vm.filterColClass},[_c('multiselect',{attrs:{"id":"goals","options":_vm.sortedTemplateGoals,"label":"key","track-by":"value","placeholder":_vm.$t('templateManager.placeholders.goals'),"multiple":true,"show-labels":false,"allow-empty":true},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var values = ref.values;
var search = ref.search;
var isOpen = ref.isOpen;
return [(values.length && !isOpen)?_c('span',{staticClass:"multiselect__single"},[_c('strong',[_vm._v(_vm._s(values[0].key))]),_vm._v(_vm._s((values.length - 1) ? (" + " + (values.length - 1) + " " + (_vm.$t('more'))) : ''))]):_vm._e()]}}]),model:{value:(_vm.selectedGoalsComputed),callback:function ($$v) {_vm.selectedGoalsComputed=$$v},expression:"selectedGoalsComputed"}})],1),_c('div',{staticClass:"template-filter-agency-categories mb-2 mb-md-0",class:_vm.filterColClass},[_c('multiselect',{attrs:{"id":"themes","options":_vm.sortedTemplateThemes,"label":"key","track-by":"value","placeholder":_vm.$t('templateManager.placeholders.theme'),"multiple":true,"show-labels":false,"allow-empty":true},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var values = ref.values;
var search = ref.search;
var isOpen = ref.isOpen;
return [(values.length && !isOpen)?_c('span',{staticClass:"multiselect__single"},[_c('strong',[_vm._v(_vm._s(values[0].key))]),_vm._v(_vm._s((values.length - 1) ? (" + " + (values.length - 1) + " " + (_vm.$t('more'))) : ''))]):_vm._e()]}}]),model:{value:(_vm.selectedThemesComputed),callback:function ($$v) {_vm.selectedThemesComputed=$$v},expression:"selectedThemesComputed"}})],1),_c('div',{staticClass:"template-filter-agency-categories mb-2 mb-md-0",class:_vm.filterColClass},[_c('multiselect',{attrs:{"id":"categories","options":_vm.sortedTemplateCategories,"label":"key","track-by":"value","placeholder":_vm.$t('templateManager.placeholders.categories'),"multiple":true,"show-labels":false,"allow-empty":true},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var values = ref.values;
var search = ref.search;
var isOpen = ref.isOpen;
return [(values.length && !isOpen)?_c('span',{staticClass:"multiselect__single"},[_c('strong',[_vm._v(_vm._s(values[0].key))]),_vm._v(_vm._s((values.length - 1) ? (" + " + (values.length - 1) + " " + (_vm.$t('more'))) : ''))]):_vm._e()]}}]),model:{value:(_vm.selectedCategoriesComputed),callback:function ($$v) {_vm.selectedCategoriesComputed=$$v},expression:"selectedCategoriesComputed"}})],1),_c('div',{staticClass:"template-filter-agency-users",class:_vm.filterColClass},[_c('multiselect',{attrs:{"id":"users","options":_vm.users.map(function (u) { return ({ key: u.name, value: u._id }); }),"label":"key","track-by":"value","placeholder":_vm.$t('templateManager.placeholders.users'),"multiple":true,"show-labels":false,"allow-empty":true},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var values = ref.values;
var search = ref.search;
var isOpen = ref.isOpen;
return [(values.length && !isOpen)?_c('span',{staticClass:"multiselect__single"},[_c('strong',[_vm._v(_vm._s(values[0].key))]),_vm._v(_vm._s((values.length - 1) ? (" + " + (values.length - 1) + " " + (_vm.$t('more'))) : ''))]):_vm._e()]}}]),model:{value:(_vm.selectedUsersComputed),callback:function ($$v) {_vm.selectedUsersComputed=$$v},expression:"selectedUsersComputed"}})],1),(_vm.databaseId === 44)?_c('div',{staticClass:"template-filter-agency-usecases",class:_vm.filterColClass},[_c('multiselect',{attrs:{"id":"usecases","options":_vm.useCases.map(function (u) { return ({ key: ((u.name[_vm.$i18n.locale]) + " (" + (u._id) + ")"), value: u._id }); }),"label":"key","track-by":"value","placeholder":_vm.$t('templateManager.placeholders.useCases'),"multiple":true,"show-labels":false,"allow-empty":true},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var values = ref.values;
var search = ref.search;
var isOpen = ref.isOpen;
return [(values.length && !isOpen)?_c('span',{staticClass:"multiselect__single"},[_c('strong',[_vm._v(_vm._s(values[0].key))]),_vm._v(_vm._s((values.length - 1) ? (" + " + (values.length - 1) + " " + (_vm.$t('more'))) : ''))]):_vm._e()]}}],null,false,3361756591),model:{value:(_vm.selectedUseCasesComputed),callback:function ($$v) {_vm.selectedUseCasesComputed=$$v},expression:"selectedUseCasesComputed"}})],1):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }
<template lang="pug">
.widget-template-col.p-0(:class="classes")
  .widget-template-card(
    :class="cardImageClass(template)"
    :key="template.name"
    @mouseover="!isMobile ? (hovered = true) : null"
    @mouseleave="!isMobile ? (hovered = false) : null"
    @click="isMobile ? (clicked = true) : null"
  )
    .widget-template-card-inner(:class="{ ssr: allowSsr, embedded: isEmbedded }")
      template-frame(
        @observable="$emit('observable', $event)"
        :allowSsr="allowSsr"
        :template="template"
        :dimensions="dimensions"
        @inited="$emit('inited')"
        @contentLoaded="onContentLoaded"
        @click="showPreviewModal(template._id, template.universal)"
        removeHidden
      )
    .widget-template-name(v-if="!minimal")
      span {{ (template.displayName || template.name) | removeLocal }}
      .widget-template-new-tag(v-if="needsNewTag") {{ $t('new') }}
    transition(name="fade")
      .widget-template-card-hover(
        v-if="hovered"
        @click="showPreviewModal(template._id, template.universal)"
      )
        .d-flex-1.flex-column.align-items-center.justify-content-center.px-3
          .widget-template-card-hover-conversion {{ conversionRate }}
          .widget-template-card-hover-conversion-text.font-size-1 {{ $t('avgConversion') }}
          .d-flex.flex-row.flex-grow-0.justify-content-center.flex-wrap.mb-2.w-100
            om-chip(color="gray" small) {{ $t(template.type) }}
            om-chip(v-for="goal in template.goals" :key="goal" color="gray" small) {{ $t(`templateFilter.goals.${goal}`) }}
        om-button(
          primary
          :id="`template-select-btn-${template._id}`"
          @click="$modal.show('template-preview', { templateId: template._id, heapTracking: { name: 'basicCard' } })"
          data-track="select design"
        ) {{ $t('details') }}
    transition(name="fade")
      .widget-template-card-mobile-click(v-if="clicked")
        close-icon(:width="20" :height="20" color="white" @click.native.stop="clicked = false")
        .widget-template-card-mobile-click-top(:class="fullScreenClass(template)")
          template-image(:template="template" full)
          .widget-template-card-mobile-click-top-content
            .widget-template-card-mobile-click-template-name {{ (template.displayName || template.name) | removeLocal }}
            .d-flex.align-items-center.justify-content-center.mb-3
              span.font-size-1 {{ $t('avgConversion') }}
              span.font-size-1--625.font-weight-bold.line-height-1.ml-2 {{ conversionRate }}
            .d-flex.flex-row.align-content-center.justify-content-center.flex-wrap.mb-3.w-100
              om-chip(color="gray") {{ $t(template.type) }}
              om-chip(v-for="goal in template.goals" :key="goal" color="gray") {{ $t(`templateFilter.goals.${goal}`) }}
        .widget-template-card-mobile-click-bottom
          om-button.brand-mobile-onboarding-btn.mt-0(
            primary
            large
            :to="{ name: 'mobile_redirect', query: { template: template._id } }"
          ) {{ $t('useTemplate') }}
          om-button.brand-mobile-onboarding-btn.mt-2(ghost large @click.stop="clicked = false") {{ $t('backToGallery') }}
</template>

<script>
  import templateCard from '@/mixins/templateCard';
  import moment from 'moment';
  import { mapGetters } from 'vuex';
  import { getConversionRate } from '@/util';
  import queryString from 'query-string';
  import useCaseRulesMixin from '@/mixins/useCaseRules';
  import ssrMixin from '@/mixins/ssr';
  import previewParentMixin from '@/mixins/previewParent';

  export default {
    mixins: [templateCard, useCaseRulesMixin, previewParentMixin, ssrMixin],

    props: {
      withSsr: {
        type: Boolean,
        default: null,
      },
      template: {
        type: Object,
        required: true,
      },
      windowBasedHeight: {
        type: Number,
        default: null,
      },
      classes: {
        type: String,
        default: '',
      },
      minimal: {
        type: Boolean,
        default: false,
      },
      dimensions: {
        type: Object,
        default: () => ({
          width: null,
          height: null,
        }),
      },
    },

    data() {
      return {
        hovered: false,
        clicked: false,
      };
    },

    computed: {
      ...mapGetters(['isMobile']),
      needsNewTag() {
        const monthDifference = moment().diff(moment(this.template.createdAt), 'months');
        return monthDifference < 1;
      },
      conversionRate() {
        return getConversionRate(this.template.conversionRate);
      },
      allowSsr() {
        if (this.withSsr === null) return this.isTemplatesWithSsr;
        return this.withSsr;
      },
      isEmbedded() {
        return this.template.type === 'embedded';
      },
    },

    watch: {
      clicked(v) {
        if (v) {
          history.pushState(
            {},
            null,
            `${this.$route.path}?mobileTemplatePreview=${this.template._id}`,
          );
        } else {
          history.replaceState({}, null, this.$route.path);
        }
      },
    },

    created() {
      this.onPopState();
      window.addEventListener('popstate', this.onPopState);
    },

    beforeDestroy() {
      window.removeEventListener('popstate', this.onPopState);
    },

    methods: {
      onPopState() {
        const parsedQuery = queryString.parse(document.location.search);
        this.clicked = parsedQuery.mobileTemplatePreview === this.template._id;
      },
      showPreviewModal(templateId) {
        if (this.isMobile) return;
        this.$modal.show('template-preview', {
          templateId,
          heapTracking: { name: 'basicCard' },
        });
      },
    },
  };
</script>

<style lang="sass">
  .widget-template-card-inner
    padding-bottom: 0 !important
</style>

<template lang="pug">
om-modal(
  name="name-campaign"
  modalClasses="om-name-campaign-modal"
  :width="500"
  headerBorder
  :clickToClose="!isUpdateMode"
  @beforeOpen="beforeOpen"
  @beforeClose="beforeClose"
  @closed="close"
)
  template(slot="modal-header")
    .row
      .col
        om-heading(h5) {{ getDomainInputModalTitle }}
    .brand-modal-action-icon.cursor-pointer(@click="$modal.hide('name-campaign')")
      close-icon(:width="12" :height="12" color="#AAB1C1")
  template(slot="modal-body")
    domain-input-v2(
      @selectedDomain="onSelectedDomain"
      :savedExternally="saveBtnClickCounter"
      @saveEvent="saveCampaign"
      :isDynamicContent="isDynamicContent"
    )
  template(slot="modal-footer")
    .d-flex.justify-content-center.align-items-center(style="flex-flow: column")
      om-button.mt-4(
        primary
        :loading="loading"
        @click="save"
        :data-track="isUpdateMode ? 'domain next' : 'domain update'"
      ) {{ isUpdateMode ? $t('save') : $t('next') }}
      .mt-3(v-if="canChange" style="color: #c4c8cd") {{ $t('newCampaignFlow.domain.canChange') }}
</template>
<script>
  import CHANGE_CAMPAIGN_SETTINGS from '@/graphql/ChangeCampaignSettings.gql';
  import CREATE_CAMPAIGN from '@/graphql/CreateCampaign.gql';
  import DomainInputV2 from '@/components/DomainInputV2.vue';
  import { getAccountIdFromCookie } from '@/util';
  import { get as _get } from 'lodash-es';
  import { required } from 'vuelidate/lib/validators';
  import { mapGetters, mapMutations, mapState } from 'vuex';
  import { createDCUrl } from '@/utils/pncURLBuilder';
  import { getRedirectDomain } from '@/utils/dcHelpers';

  export default {
    components: {
      DomainInputV2,
    },

    data() {
      return {
        campaignName: '',
        selectedDomain: null,
        loading: false,
        saveBtnClicked: false,
        saveBtnClickCounter: 0,
        colors: [],
        mode: null,
        colorChange: false,
        theme: undefined,
        canChange: true,
        isDynamicContent: false,
        addControlVariant: false,
        sabOnly: false,
      };
    },

    computed: {
      ...mapGetters(['brandName', 'domains', 'isSuperAdmin']),
      ...mapState(['account']),
      getDomainInputModalTitle() {
        if (this.isDynamicContent) {
          return this.$t('newCampaignFlow.dcDomainSelect');
        }

        return this.$t('newCampaignFlow.domainSelect', { brand: this.brandName });
      },
      isUpdateMode() {
        return this.mode === 'update';
      },
      isOnboarding() {
        return this.$route.fullPath.includes('/onboarding');
      },
    },

    validations: {
      selectedDomain: {
        required,
      },
    },
    mounted() {
      this.$bus.$on('save-campaign', async ({ selectedDomain, domain }) => {
        this.selectedDomain = selectedDomain;
        await this.saveCampaignRaw(domain);
      });
    },
    beforeDestroy() {
      this.$bus.$off('save-campaign');
    },

    methods: {
      ...mapMutations(['changeFormManagerVisibility']),
      beforeOpen(event) {
        this.templateId = event.params.templateId;
        this.colors = event.params.colors;
        this.campaignName = '';
        this.selectedDomain = null;
        this.saveBtnClicked = false;
        this.saveBtnClickCounter = 0;
        this.mode = event.params.mode || null;
        this.colorChange = event.params.colorChange || false;
        this.theme = event.params.theme || undefined;
        this.canChange = event.params.canChange ?? true;
        this.isDynamicContent = event.params.isDynamicContent ?? false;
        this.addControlVariant = event.params.addControlVariant ?? false;
        this.sabOnly = event.params.sabOnly ?? false;

        if (this.isUpdateMode) {
          // remove ESC listener to prevent modal close
          window.removeEventListener('keyup', this.$children[0].$children[0].onEscapeKeyUp);
        }
      },

      beforeClose() {
        if (this.isUpdateMode) {
          // add ESC listener to restore og functionality
          window.addEventListener('keyup', this.$children[0].$children[0].onEscapeKeyUp);
        }
      },

      save() {
        if (this.saveBtnClicked) {
          return;
        }

        this.saveBtnClickCounter++;
      },
      validateDomainForDC() {
        const { _id } = this.selectedDomain;
        const domainSettings = this.domains.find(({ _id: domainId }) => domainId === _id);
        if (!domainSettings) return false;

        const fromDate = parseInt(new Date(domainSettings?.v3LastRequestDate).getTime() / 1000, 10);
        const toDate = parseInt(new Date().getTime() / 1000, 10);
        const diff = parseInt((toDate - fromDate) / 3600, 10);
        const MAX_DIFF_IN_HOURS = 168;
        if (diff > MAX_DIFF_IN_HOURS) {
          return false;
        }
        return true;
      },
      async saveCampaignRaw(domain) {
        if (!this.selectedDomain) return;

        try {
          let source = '';
          let sourceUrl = '';
          if (this.$route.path.includes('use-case')) {
            source = 'use-case';
            sourceUrl = `?source=use-case&use-case-id=${this.$route.params.id}`;
          }

          const r = await this.$apollo.mutate({
            mutation: CREATE_CAMPAIGN,
            variables: {
              input: {
                name: this.campaignName,
                domainId: this.selectedDomain._id,
                templateId: this.templateId,
                colors: this.colors,
                theme: this.theme,
                dynamicContent: this.isDynamicContent,
                addControlVariant: this.addControlVariant,
                source,
              },
            },
          });

          const campaignId = _get(r, 'data.createCampaign.id');
          const variantId = _get(r, 'data.createCampaign.variants.0._id');

          if (!campaignId || !variantId) {
            throw new Error('Failed to create campaign');
          }

          this.loading = false;

          if (this.isDynamicContent) {
            const [variant] = _get(r, 'data.createCampaign.variants');
            const campaignDomain = _get(r, 'data.createCampaign.domain');
            const url = this.getDynamicContentUrl(
              getRedirectDomain(domain?.originalInput, campaignDomain),
              campaignId,
              this.$i18n.locale,
              variant._id,
              domain?.pathName ? domain.pathName : '',
            );

            window.open(url, '_self');
          } else {
            window.location = `/${getAccountIdFromCookie()}/variant/${campaignId}/${variantId}/edit/new${sourceUrl}`;
          }
        } catch (e) {
          this.loading = false;
          this.saveBtnClicked = false;
          console.log(e);
          this.$notify({
            type: 'error',
            text: this.$t('notifications.saveError'),
          });
        }
      },
      getDynamicContentUrl(campaignDomain, campaignId, locale, variantId, pathName) {
        return createDCUrl({
          domain: campaignDomain,
          campaignId,
          locale,
          variantId,
          path: pathName,
          returnUrl: `/${this.account.databaseId}/campaign/${campaignId}/settings?activeBox=condition`,
          backUrl: `/${this.account.databaseId}/campaign/${campaignId}`,
          isNew: true,
          isSuperAdmin: this.isSuperAdmin,
          featureMode: this.sabOnly ? 'smart-ab-test' : null,
        });
      },

      async saveCampaign(domain) {
        if (domain?.selectedDomain) {
          this.selectedDomain = domain.selectedDomain;
        }

        this.$v.$touch();
        if (this.$v.$invalid) {
          this.$notify({
            type: 'error',
            text: this.$t('notifications.selectOrAddDomain'),
          });
          return;
        }

        if (this.isUpdateMode) return this.updateCampaignDomain();

        this.saveBtnClicked = true;
        this.loading = true;

        if (this.isDynamicContent) {
          const isDomainCorrect = this.validateDomainForDC();
          if (!isDomainCorrect && !this.isOnboarding) {
            this.loading = false;
            this.$modal.hide('name-campaign');
            this.$modal.show('last-request-date-old', {
              selectedDomain: this.selectedDomain,
              domain,
            });
            return;
          }
        }
        await this.saveCampaignRaw(domain);
      },
      async updateCampaignDomain() {
        const { _id: domainId, domain } = this.selectedDomain;
        const { campaignId } = this.$store.state.campaign;
        try {
          const { data: result } = await this.$apollo.mutate({
            mutation: CHANGE_CAMPAIGN_SETTINGS,
            variables: {
              input: {
                _id: campaignId,
                domainId,
                domain,
              },
            },
          });

          if (result) {
            const {
              changeCampaignSettings: { success, message },
            } = result;

            if (success) {
              this.$store.state.campaign.domain = domain;
              this.$emit('domainUpdated');
              this.$modal.hide('name-campaign');
              return;
            }
            this.$notify({
              type: 'error',
              text: message || this.$t('notifications.saveError'),
            });
          } else {
            this.$notify({
              type: 'error',
              text: this.$t('notifications.saveError'),
            });
          }
        } catch (e) {
          console.log('@@@error', { e });
          this.$notify({
            type: 'error',
            text: this.$t('notifications.saveError catch'),
          });
        }
      },
      onSelectedDomain(domain) {
        // reset double click detection on domain change
        this.saveBtnClicked = false;
        this.selectedDomain = domain;
        // if (this.selectedDomain) setTimeout(() => this.saveCampaign(), 100)
      },
      close() {
        this.$emit('close');
      },
    },
  };
</script>
<style lang="sass">
  .om-name-campaign-modal
    .brand-modal-footer
      padding-top: 0 !important
    .brand-modal-body
      padding-left: 40px !important
      padding-right: 40px !important
      padding-bottom: 0 !important
</style>

<template lang="pug">
.brand-lazy-template-image(ref="lazyImage")
</template>
<script>
  import { get as _get } from 'lodash-es';
  import { getCdnUrl } from '@/config/cdn';
  import { isFullScreenTemplate } from '@/util';

  export default {
    props: {
      template: {
        type: Object,
        default: () => ({}),
      },
      full: {
        type: Boolean,
        default: false,
      },
    },

    data() {
      return {
        observer: null,
      };
    },

    computed: {
      previewUrl() {
        const preview = _get(this.template, 'previewUrls.0');
        const thumbnail = _get(this.template, 'thumbnail.0', preview);

        return thumbnail;
      },
      imageUrl() {
        let url = this.previewUrlToCdn(this.previewUrl);
        if (this.template.previewGeneratedAt)
          url += `?at=${new Date(this.template.previewGeneratedAt).getTime()}`;
        return url;
      },
      position() {
        if (this.template.type === 'nanobar') return 'top center';
        if (this.template.type === 'sidebar' && !this.full) return 'right bottom';

        return 'center center';
      },
      size() {
        if (this.full) return 'contain';
        if (isFullScreenTemplate(this.template)) return '100%';
        if (this.template.type === 'sidebar') {
          if (this.template.isFullHeight) return 'auto 95%';
          return 'auto 60%';
        }
        if (this.template.type === 'popup') return 'auto 70%';

        return 'contain';
      },
      backgroundImageUrl() {
        return `url(${this.imageUrl}) transparent no-repeat ${this.position} / ${this.size}`;
      },
    },

    watch: {
      backgroundImageUrl(n) {
        if (n) {
          this.$refs.lazyImage.style.background = n;
        }
      },
    },

    mounted() {
      this.observer = new IntersectionObserver(
        (entries) => {
          entries.forEach((entry) => {
            if (entry.isIntersecting) {
              this.observer.unobserve(entry.target);
              entry.target.style.background = this.backgroundImageUrl;
            }
          });
        },
        {
          rootMargin: '0px 0px 400px 0px',
        },
      );

      this.observer.observe(this.$refs.lazyImage);
    },

    beforeDestroy() {
      this.observer.disconnect();
    },

    methods: {
      previewUrlToCdn(previewUrl) {
        let result;
        if (process.env.NODE_ENV !== 'development') {
          const cdnUrl = getCdnUrl().replace('//', '');
          const url = new URL(previewUrl);
          url.host = cdnUrl;
          result = url.href;
        } else {
          result = previewUrl;
        }
        return result;
      },
    },
  };
</script>

<style lang="sass">
  .brand-lazy-template-image
    width: 100%
    height: 100%
    .widget-template-card-mobile-click-top &
      min-height: 170px
      max-height: 295px
</style>

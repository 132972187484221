export default {
  color: 'rgba(49, 80, 85, .85)',
  color2: '#fff',
  type: 'solid-fill',
  gradientType: 'linear',
  gradientCenter: 'circle at center',
  linearDirection: 45,
  transparency: 1,
  imageId: '',
  imagePosition: 'cover',
  imageRepeat: 'no-repeat',
  resizeType: null,
};

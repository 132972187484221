const setWith = require('lodash.setwith');
const {
  handleTemplateElementsExceptions,
  handleImageExceptions,
} = require('@om/template-properties/src/exceptions/');
const { radiusProperties, ELEMENTS } = require('./propertyHelper');
const { GROUP_NAMES, GROUPS, EXCEPTIONS } = require('./types/radiusTypes');

const FULLSCREEN = 'fullscreen';
const INTERSTITAL = 'interstitial';
const NANOBAR = 'nano';
const NANOBAR2 = 'nanobar';

const NO_NEED_TO_ROUNDING_TYPES = [FULLSCREEN, INTERSTITAL, NANOBAR, NANOBAR2];

const inCaseOfLuckyWheelNoRounding = (elements) => {
  return !elements.find(({ type }) => type === 'OmLuckyWheel');
};

const getBorderRadiusFor = (group, elementType, elements = null, popupType = null, uid = null) => {
  if (elements && !inCaseOfLuckyWheelNoRounding(elements) && elementType === ELEMENTS.OmPage)
    return 0;
  if (NO_NEED_TO_ROUNDING_TYPES.includes(popupType) && elementType === ELEMENTS.OmPage) return 0;

  let radius = 0;

  const element = elements?.find((element) => element.uid === uid);
  const isPageWithBorder =
    element &&
    elementType === ELEMENTS.OmPage &&
    element.desktop.border.selectedBorder !== 'no' &&
    element.desktop.border.width > 0;

  if (group === GROUP_NAMES.SMALL) {
    radius = GROUPS[GROUP_NAMES.SMALL];
    if (isPageWithBorder) {
      radius *= 3.5;
    }
  }
  if (group === GROUP_NAMES.MEDIUM) {
    radius = GROUPS[GROUP_NAMES.MEDIUM];
    if (isPageWithBorder) {
      radius *= 2.25;
    }
  }
  if (group === GROUP_NAMES.LARGE) {
    radius = GROUPS[GROUP_NAMES.LARGE];
    if (isPageWithBorder) {
      radius *= 1.625;
    }
  }

  if (isPageWithBorder) {
    if (element.desktop?.border?.width) {
      const { width } = element.desktop.border;
      radius *= 1 + width / 100;
    }
  }

  return Math.floor(radius);
};

const setCloseButtonRadius = (group) => {
  const radius = getBorderRadiusFor(group, ELEMENTS.OmCloseButton);
  const path = 'borderRadius';
  return { path, radius };
};

const _updateElementRadius = (element, propertyPath, value, device = 'desktop') => {
  const replacedPath = propertyPath.replace('$device', device);
  setWith(element, replacedPath, value);
};

const _handleExceptions = (exception, borderTypeValue) => {
  return EXCEPTIONS[exception.radius].map((radius) => {
    if (exception.device === 'desktop') {
      return radius ? borderTypeValue : 0;
    }
    if (exception.device === 'mobile') {
      return radius ? borderTypeValue : 1;
    }
    return 0;
  });
};

const _updateRadius = (element, group, radius, exceptions, elements, popupType) => {
  if (!element) return;

  const exceptionMatches = exceptions?.filter((exception) => exception.uid === element.uid);
  const borderTypeValue = getBorderRadiusFor(group, element.type, elements, popupType);
  if (exceptionMatches?.length) {
    exceptionMatches.forEach((exception) => {
      const exceptionsRadiuses = _handleExceptions(exception, borderTypeValue);
      _updateElementRadius(element, radius, exceptionsRadiuses, exception.device);
    });
  } else {
    _updateElementRadius(element, radius, Array(4).fill(borderTypeValue));
  }
};

const updateRadiusForElement = (element, group, type, exceptions, elements, popupType) => {
  const elementRadiusProperty = radiusProperties(type);
  if (!elementRadiusProperty) return;

  if (Array.isArray(elementRadiusProperty)) {
    elementRadiusProperty.forEach((radiusItem) => {
      _updateRadius(element, group, radiusItem, exceptions, elements, popupType);
    });
  } else {
    _updateRadius(element, group, elementRadiusProperty, exceptions, elements, popupType);
  }
};

const updateRadiusForElements = (elements, group, exceptions = null, popupType) => {
  const modifiedElementIds = [];

  elements.forEach((element) => {
    updateRadiusForElement(element, group, element.type, exceptions, elements, popupType);
    modifiedElementIds.push(element.uid);
  });
  return modifiedElementIds;
};

const getExceptions = (elements) => {
  return [...handleImageExceptions(elements), ...handleTemplateElementsExceptions(elements)];
};

const getBorderRadiusValueForElement = (elementType, uid, type, elements, popupType) => {
  const exceptions = getExceptions(elements);
  const exceptionMatches = exceptions?.filter((exception) => exception.uid === uid);
  const borderTypeValue = getBorderRadiusFor(type, elementType, elements, popupType, uid);
  if (exceptionMatches?.length) {
    return exceptionMatches.map((exception) => {
      const exceptionRadius = _handleExceptions(exception, borderTypeValue);
      return { [exception.device]: exceptionRadius };
    });
  }
  return Array(4).fill(borderTypeValue);
};

module.exports = {
  updateRadiusForElements,
  getBorderRadiusFor,
  updateRadiusForElement,
  setCloseButtonRadius,
  getBorderRadiusValueForElement,
};

<template lang="pug">
mixin cards
  template(v-for="(tactic, index) in getTactics")
    div(:class="cardWrapperClass")
      slot(name="advanced-tactics-recommendation-card" :tactic="{ index, data: tactic }")

.advanced-tactics
  template(v-if="!loader")
    .advanced-tactics-header
      slot(name="advanced-tactics-recommendation-header")

    .advanced-tactics-content
      slot(name="advanced-tactics-recommendation-content-before-cards")

      template(v-if="isHorizontal")
        .advanced-tactics-cards-horizontal
          +cards
      template(v-else-if="isVertical")
        .advanced-tactics-cards-vertical
          +cards
      template(v-else-if="isSlider")
        .advanced-tactics-cards-slider
          slider
            template(slot="tactics-slider-cards")
              +cards
      template(v-else)
        .advanced-tactics-cards
          +cards

      slot(name="advanced-tactics-recommendation-content-after-cards")
</template>

<script>
  import Slider from '@/components/Tactics/Slider.vue';
  import GET_ADVANCED_TACTICS_RECOMMENDATION from '@/graphql/GetAdvancedTacticsRecommendation.gql';

  export default {
    components: {
      Slider,
    },
    props: {
      limit: {
        type: Number,
        require: true,
      },
      isHorizontal: {
        type: Boolean,
        default: false,
      },
      isVertical: {
        type: Boolean,
        default: false,
      },
      isSlider: {
        type: Boolean,
        default: false,
      },
    },

    data() {
      return {
        loader: false,
        tactics: [],
      };
    },

    computed: {
      getTactics() {
        const normalizedLimit =
          this.limit >= this.tactics.length ? this.tactics.length : this.limit;
        return this.tactics.slice(0, normalizedLimit);
      },
      cardWrapperClass() {
        return this.isSlider ? 'swiper-slide' : 'advanced-tactics-recommendation-card-wrapper';
      },
    },

    created() {
      this.initiate();
    },

    methods: {
      async initiate() {
        this.loader = true;
        await this.fetchRecommendedAdvancedTactics();
        this.loader = false;
        this.$emit('loaded');
      },

      async fetchRecommendedAdvancedTactics() {
        const {
          data: { useCases },
        } = await this.$apollo.query({
          query: GET_ADVANCED_TACTICS_RECOMMENDATION,
          variables: {
            limit: this.limit,
          },
        });
        this.tactics = useCases;
      },
    },
  };
</script>

<style lang="sass">
  .advanced-tactics-cards-horizontal
    display: grid
    grid-template-columns: 1fr 1fr 1fr
    gap: 35px

  .advanced-tactics-recommendation-card-wrapper
    display: contents
</style>

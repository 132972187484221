<template lang="pug">
  .widget-template-col.col-12.col-md-6.col-lg-4.col-xl-3
    .widget-template-card.create-new-card(@click="createNewTemplate")
      .create-new-plus +
</template>
<script>
  export default {
    props: { agency: Boolean, extra: [Object, null] },
    methods: {
      createNewTemplate() {
        let params = { agency: this.agency };

        if (this.extra) {
          params = {
            ...params,
            ...this.extra,
          };
        }
        this.$modal.show('new-template', params);
      },
    },
  };
</script>

<style lang="sass"></style>

<template lang="pug">
.brand-template-progress-items
  progress-item(
    v-for="(item, index) in items"
    :completed="isStepCompleted(index)"
    :index="index + 1"
    :last="items.length - 1 === index"
    :key="`progress-item-${index}`"
  )
    div {{ item.text }}
</template>
<script>
  import ProgressItem from './ProgressItem.vue';

  export default {
    components: { ProgressItem },

    data() {
      return {
        items: [
          {
            text: this.$t('chooseATemplate'),
            routes: ['new_campaign', 'themes_list', 'theme_details'],
          },
          { text: 'Design', routes: ['variant_edit'] },
          { text: this.$t('displayRules'), routes: ['campaign_settings'] },
        ],
      };
    },

    methods: {
      isStepCompleted(index) {
        const foundIndex = this.items.findIndex((i) => i.routes.includes(this.$route.name));

        return index <= foundIndex;
      },
    },
  };
</script>

<style lang="sass">
  .brand-template-progress-items
    display: flex
</style>

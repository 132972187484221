<template lang="pug">
.widget-template-col.col-12.col-md-6.col-lg-4.col-xl-3
  .widget-template-card(:class="cardImageClass(template)" :key="template.name")
    .widget-template-card-inner(:class="{ ssr: isTemplatesWithSsr }")
      template-frame(
        @observable="$emit('observable', $event)"
        :dimensions="dimensions"
        @inited="$emit('inited')"
        :allowSsr="isTemplatesWithSsr"
        :template="template"
        @contentLoaded="onContentLoaded"
      )
      .widget-template-img-hover.d-flex.justify-content-center(
        v-if="draggable && template.status !== 'draft'"
      )
        .widget-drag
          i.far.fa-hand-rock.mr-2
          span {{ $t('templateManager.dragAndMove') }}
    .widget-template-inner-bottom
      .widget-template-name.d-flex.align-items-center.py-2
        input(
          v-if="!agency && !isAffiliate"
          type="checkbox"
          :checked="selected"
          @change="selectionChange"
          :value="template._id"
        )
        .flex-grow-1.widget-template-name-ellipsis(:title="templateName") {{ templateName }}
        popper(trigger="click" :options="{ placement: 'bottom' }" @created="actionsMenu = $event")
          ul.widget-actions
            li.widget-actions-item.d-flex.align-items-center.font-size-0--8125(
              v-if="editable || databaseId === 44"
            )
              .widget-actions-icon.mr-2
                EditTemplate
              router-link.widget-action-link(
                :to="{ name: 'template_editor', params: { userId: getAccountIdFromCookie(), baseTemplate: template.name } }"
              )
                span {{ $t('templateManager.editTemplate') }}
            li.widget-actions-item.align-items-center.d-flex.font-size-0--8125(
              v-if="tab !== 'partnerFeatured' && canManage && template.status !== 'published'"
              @click="changeStatus('published')"
            )
              .widget-actions-icon.mr-2
                Publish
              span {{ $t('templateManager.publish') }}
            li.widget-actions-item.align-items-center.d-flex.font-size-0--8125(
              v-if="tab !== 'partnerFeatured' && canManage && template.status !== 'draft'"
              @click="changeStatus('draft')"
            )
              .widget-actions-icon.mr-2
                MakeItDraft
              span {{ $t('templateManager.unpublish') }}
            li.widget-actions-item.align-items-center.d-flex.font-size-0--8125(
              v-if="databaseId === 44 && template.status !== 'unlisted'"
              @click="changeStatus('unlisted')"
            )
              .widget-actions-icon.mr-2
                MakeItUnlisted
              span {{ $t('templateManager.unlist') }}
            li.widget-actions-item.align-items-center.d-flex.font-size-0--8125(
              v-if="template.status === 'unlisted'"
              @click="openUnlistedLink"
            )
              .widget-actions-icon.mr-2
                OpenLink
              span {{ $t('templateManager.openLink') }}
            li.widget-actions-item.align-items-center.d-flex.font-size-0--8125(
              v-if="databaseId === 44 && !template.universal"
              @click="toggleSeasonal"
            )
              .widget-actions-icon.mr-2
                MakeSeasonal
              span {{ template.seasonal ? $t('removeFromSeasonal') : $t('makeSeasonal') }}
            li.widget-actions-item.align-items-center.d-flex.font-size-0--8125(
              @click="$modal.show('name-duplicate-template', { templateId: template._id, universal: template.universal })"
            )
              .widget-actions-icon.mr-2
                Duplicate
              span {{ $t('templateManager.duplicate') }}
            li.widget-actions-item.align-items-center.d-flex.font-size-0--8125(
              v-if="canManage"
              @click="$modal.show('new-template', { agency, template })"
            )
              .widget-actions-icon.mr-2
                EditSettings
              span {{ $t('templateManager.editSettings') }}
            li.widget-actions-item.align-items-center.d-flex.font-size-0--8125(
              v-if="isAuthor || databaseId === 44"
              @click="deleteTemplate"
            )
              .widget-actions-icon.mr-2
                DeleteTemplate
              span {{ $t('templateManager.deleteTemplate') }}
            li.widget-actions-item.align-items-center.d-flex.font-size-0--8125(
              v-if="isAffiliate && !isPartnerFeatured"
              @click="togglePartnerFeatured"
            )
              .widget-actions-icon.mr-2
                MakeFeatured
              span {{ $t('templateManager.addToFeatured') }}
            li.widget-actions-item.align-items-center.d-flex.font-size-0--8125(
              v-if="isAffiliate && isPartnerFeatured"
              @click="togglePartnerFeatured"
            )
              .widget-actions-icon.mr-2
                Minus
              span {{ $t('templateManager.removeFromFeatured') }}
          div(slot="reference")
            .cursor-pointer.actions-toggle.brand-widget-setting-icon
              Toggle
        //- (@click.prevent="$modal.show('new-template', {template, agency})")
      .brand-separator
      template(v-if="template.theme")
        .widget-template-goals(:title="template.theme") {{ template.theme }}
        .brand-separator
      .widget-template-goals(:title="goalsWithTranslationsFull") {{ goalsWithTranslationsCut }}
      .brand-separator(v-if="agency")
      template(v-if="agency")
        .widget-template-users.py-2.text-center(
          v-if="typeof usersList() === 'string' || usersList().length < 2"
        )
          span.brand-text-muted {{ $t('templateManager.sharedWith') }}:
          |
          | {{ usersList(true) }}
        popper(v-else trigger="click" :options="{ placement: 'bottom' }")
          .widget-template-users.px-2
            ul.widget-template-users-list
              li(v-for="user in usersList()") {{ user }}
          div(slot="reference")
            .cursor-pointer.text-center.py-2
              span.brand-text-muted {{ $t('templateManager.sharedWith') }}:
              |
              | {{ usersList(true) }}
</template>

<script>
  import { get as _get } from 'lodash-es';
  import { mapGetters } from 'vuex';

  import CHANGE_TEMPLATE_STATUS from '@/graphql/ChangeTemplateStatus.gql';
  import TOGGLE_TEMPLATE_SEASONAL from '@/graphql/ToggleTemplateSeasonal.gql';
  import TOGGLE_TEMPLATE_PARTNER_FEATURED from '@/graphql/ToggleTemplatePartnerFeatured.gql';

  import * as Icons from '@/assets/admin/svg/templateManager/actions';
  import Toggle from '@/assets/admin/svg/templateManager/Toggle.vue';

  import templateCard from '@/mixins/templateCard';
  import templateManager from '@/mixins/templateManager';
  import previewParentMixin from '@/mixins/previewParent';
  import ssrMixin from '@/mixins/ssr';

  import { slugify, getAccountIdFromCookie as _getAccountIdFromCookie } from '@/util';
  import runtimeConfig from '@/config/runtime';

  export default {
    components: { ...Icons, Toggle },
    mixins: [templateCard, templateManager, previewParentMixin, ssrMixin],
    props: {
      template: {
        type: Object,
        default: () => {},
      },
      windowBasedHeight: {
        type: Number,
        default: 50,
      },
      agency: Boolean,
      editable: Boolean,
      users: {
        type: Array,
        default: () => [],
      },
      useCases: {
        type: Array,
        default: () => [],
      },
      selected: {
        type: Boolean,
        default: false,
      },
      tab: {
        type: String,
        default: '',
      },
      draggable: {
        type: Boolean,
        default: true,
      },
      dimensions: {
        type: Object,
        default: () => ({
          width: null,
          height: null,
        }),
      },
    },
    data: () => ({
      actionsMenu: null,
    }),
    computed: {
      ...mapGetters(['databaseId', 'isAffiliate']),
      goalsWithTranslationsFull() {
        return (this.template.goals || [])
          .map((g) => this.$t(`templateFilter.goals.${g}`))
          .join(', ');
      },

      goalsWithTranslationsCut() {
        const goalsLength = (this.template.goals || []).length;

        if (goalsLength === 0) {
          return '-';
        }

        if (goalsLength > 3) {
          return `${this.template.goals
            .slice(0, 3)
            .map((g) => this.$t(`templateFilter.goals.${g}`))
            .join(', ')} + ${goalsLength - 3}`;
        }

        return this.goalsWithTranslationsFull;
      },

      useCase() {
        return this.useCases.find(({ _id }) => this.template.useCase === _id);
      },

      templateName() {
        if (!this.useCases || !this.template.universal) {
          return this.template.displayName || this.template.name;
        }

        if (this.useCase) {
          return this.useCase.name[this.$i18n.locale];
        }

        if (this.databaseId === 44) {
          return `${this.template.displayName || this.template.name} - no use case`;
        }

        return `${this.template.displayName} - no use case`;
      },

      isAuthor() {
        return this.template.author && this.template.author.databaseId === this.databaseId;
      },

      cardInnerDynamicClasses() {
        return [`widget-template-${this.template.type}`];
      },

      isPartnerFeatured() {
        return _get(this.template, 'partnerFeatured', []).includes(this.databaseId);
      },

      canManage() {
        return (this.isAffiliate && this.isAuthor) || this.agency || this.databaseId === 44;
      },
    },

    methods: {
      async changeStatus(newStatus) {
        const {
          data: { success },
        } = await this.$apollo.mutate({
          mutation: CHANGE_TEMPLATE_STATUS,
          variables: {
            templateId: this.template._id,
            status: newStatus,
          },
        });

        if (success) {
          this.$emit('statusChanged', {
            templateId: this.template._id,
            oldType: this.template.status,
            newType: newStatus,
            field: 'status',
            value: newStatus,
          });
        }
      },

      async toggleSeasonal() {
        const {
          data: { success },
        } = await this.$apollo.mutate({
          mutation: TOGGLE_TEMPLATE_SEASONAL,
          variables: {
            templateId: this.template._id,
            value: !this.template.seasonal,
          },
        });

        if (success) {
          let oldType;
          let newType;
          if (this.template.seasonal) {
            oldType = 'seasonal';
            newType = 'published';
          } else {
            oldType = 'published';
            newType = 'seasonal';
          }

          this.$emit('statusChanged', {
            templateId: this.template._id,
            oldType,
            newType,
            field: 'seasonal',
            value: !this.template.seasonal,
          });
        }
      },
      deleteTemplate() {
        this.actionsMenu.doClose();
        this.$emit('delete', this.template._id);
      },

      usersList(cut) {
        if (!this.template.users.length) return this.$t('templateManager.all');
        const tplUsers = this.template.users
          .map((id) => this.users.find((u) => u._id === id))
          .filter((v) => !!v);
        if (!cut) {
          return tplUsers.map((u) => u.name);
        }
        if (tplUsers.length > 1) {
          let str = tplUsers[0].name;
          str += ` + ${tplUsers.length - 1} ${this.$t('more')}`;
          return str;
        }
        return tplUsers[0] ? tplUsers[0].name : this.$t('templateManager.all');
      },

      getAccountIdFromCookie() {
        return _getAccountIdFromCookie();
      },

      openUnlistedLink() {
        if (!this.useCase) {
          this.$notify({
            type: 'error',
            text: this.$t('templateManager.errors.noUseCase'),
          });
          return;
        }

        if (!this.template.theme) {
          this.$notify({
            type: 'error',
            text: this.$t('templateManager.errors.noTheme'),
          });
          return;
        }

        const link = `${runtimeConfig.VUE_APP_TEMPLATE_LIBRARY_URL}/use-cases/${slugify(
          this.useCase.name.en,
        )}?preview=${this.template._id}`;
        Object.assign(document.createElement('a'), { target: '_blank', href: link }).click();
      },

      selectionChange(event) {
        if (event.currentTarget.checked) {
          this.$emit('selected', this.template._id);
        } else {
          this.$emit('unselected', this.template._id);
        }
      },

      async togglePartnerFeatured() {
        const {
          data: { toggleTemplatePartnerFeatured },
        } = await this.$apollo.mutate({
          mutation: TOGGLE_TEMPLATE_PARTNER_FEATURED,
          variables: {
            templateId: this.template._id,
          },
        });

        if (toggleTemplatePartnerFeatured) {
          let oldType = this.getTemplateType(this.template);
          let newType = 'partnerFeatured';
          let value = [this.template.partnerFeatured, this.databaseId];
          let removeFromOld = true;
          let addToNew = true;

          if (this.isPartnerFeatured) {
            oldType = 'partnerFeatured';
            newType = this.getTemplateType(this.template);
            value = this.template.partnerFeatured.filter((u) => u !== this.databaseId);
          }

          if (this.tab === 'partnerFeatured') {
            removeFromOld = true;
            addToNew = false;
          } else {
            if (oldType !== 'partnerFeatured') {
              removeFromOld = false;
            }

            if (oldType === 'partnerFeatured') {
              addToNew = false;
            }
          }

          this.$emit('statusChanged', {
            templateId: this.template._id,
            oldType,
            newType,
            field: 'partnerFeatured',
            value,
            removeFromOld,
            addToNew,
          });
        }
      },
    },
  };
</script>

<style lang="sass">
  .sortable-ghost
    opacity: 0.3

  .widget-template-name-ellipsis
    overflow: hidden
    text-overflow: ellipsis
    max-width: 11.5rem
    white-space: nowrap
    display: inline-block
  a.widget-action-link
    color: black
    text-decoration: none
</style>

const _clone = (v) => JSON.parse(JSON.stringify(v));
export const scratchOptionsDef = [
  { weigth: 1, title: 'First', couponCode: 'FIRST100' },
  { weigth: 0, title: '', couponCode: '' },
  { weigth: 0, title: '', couponCode: '' },
  { weigth: 0, title: '', couponCode: '' },
];

export default (settings) => {
  return settings || _clone(scratchOptionsDef);
};

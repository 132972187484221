import { get as _get } from 'lodash-es';
import { getCdnUrl } from '@/config/cdn';
import TemplateImage from '@/components/Template/TemplateImage';
import { isFullScreenTemplate, isCenteredBottom } from '@/util';

const previewUrlToCdn = (previewUrl) => {
  let result;
  if (process.env.NODE_ENV !== 'development') {
    const cdnUrl = getCdnUrl().replace('//', '');
    const url = new URL(previewUrl);
    url.host = cdnUrl;
    result = url.href;
  } else {
    result = previewUrl;
  }
  return result;
};

export default {
  components: { TemplateImage },

  filters: {
    removeLocal(value) {
      return value.replace('_hu', '');
    },
  },

  methods: {
    cardImageClass(template) {
      const isFullscreen = isFullScreenTemplate(template);

      return {
        'widget-template-interstitial': isFullscreen,
        'widget-template-centered-bottom': isCenteredBottom(template),
      };
    },

    fullScreenClass(template) {
      return {
        'full-screen-template': isFullScreenTemplate(template) || template.type === 'nanobar',
        [`full-screen-template-${template.type}`]:
          template.type === 'nanobar' || template.type === 'interstitial',
      };
    },

    backgroundImageUrl(template) {
      let url = previewUrlToCdn(_get(template, 'previewUrls.0'));
      if (template.previewGeneratedAt) url += `?at=${template.previewGeneratedAt}`;
      return url;
    },
    backgroundImage(template) {
      let slide = previewUrlToCdn(_get(template, 'previewUrls.0'));
      if (template.previewGeneratedAt) slide += `?at=${template.previewGeneratedAt}`;
      return {
        background: `url(${slide}) no-repeat center center / contain`,
      };
    },
  },
};

<template>
  <svg
    :width="width"
    :height="height"
    viewBox="0 0 8 8"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="4" cy="4" r="4" :fill="color" />
  </svg>
</template>

<script>
  export default {
    props: {
      color: { type: String, default: '#B9BEC6' },
      height: { type: Number, default: 8 },
      width: { type: Number, default: 8 },
    },
  };
</script>

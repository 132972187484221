export const tacticLibraryUrl = {
  en: 'https://library.optimonk.com/templates/use-cases',
  hu: 'https://library.optimonk.hu/templates/use-cases',
};

export const slugify = (str) => {
  const specialChars = {
    à: 'a',
    ä: 'a',
    á: 'a',
    â: 'a',
    æ: 'a',
    å: 'a',
    ë: 'e',
    è: 'e',
    é: 'e',
    ê: 'e',
    î: 'i',
    ï: 'i',
    ì: 'i',
    í: 'i',
    ò: 'o',
    ó: 'o',
    ö: 'o',
    ô: 'o',
    ø: 'o',
    ù: 'o',
    ú: 'u',
    ü: 'u',
    û: 'u',
    ñ: 'n',
    ç: 'c',
    ß: 's',
    ÿ: 'y',
    œ: 'o',
    ŕ: 'r',
    ś: 's',
    ń: 'n',
    ṕ: 'p',
    ẃ: 'w',
    ǵ: 'g',
    ǹ: 'n',
    ḿ: 'm',
    ǘ: 'u',
    ẍ: 'x',
    ź: 'z',
    ḧ: 'h',
    '·': '-',
    '/': '-',
    _: '-',
    ',': '-',
    ':': '-',
    '': '-',
  };

  return str
    .replace(/./g, (target) => specialChars[target] || target)
    .replace(/([a-z0-9])([A-Z])/g, '$1-$2')
    .replace(/([A-Z])([A-Z])(?=[a-z])/g, '$1-$2')
    .replace(/\s+/g, '-')
    .toLowerCase()
    .trim();
};

export const fixOptimizationTactics = [
  {
    tags: [
      {
        en: 'A/B test',
        hu: 'A/B tesztelés',
      },
    ],
    name: {
      en: 'A/B test two or more versions of a page against each other.',
      hu: 'A/B teszteld egy oldal két vagy több verzióját egymás ellen.',
    },
    usage: {
      en: 'Test headlines, offers, designs & CTAs easily against each other or a control to find the highest-performer variant.',
      hu: 'Tesztelj címsorokat, ajánlatokat, design-t és CTA-kat egymás, vagy egy kontroll variáns ellen, hogy megtalálhasd a legjobban teljesítő változatot.',
    },
    image: 'fix-tactics/ab-test.png',
    content: {
      en: 'https://www.optimonk.com/templates/use-cases/landing-page-a-b-test-64b93c2949a22f0028c7aab0',
      hu: 'https://www.optimonk.hu/templates/use-cases/landolo-oldal-a-b-tesztel%C5%91-64b93c2949a22f0028c7aab0',
    },
    hasSelectHandler: true,
  },
  {
    tags: [
      {
        en: 'Smart A/B test',
        hu: 'Smart A/B tesztelés',
      },
      {
        en: 'Upgrade',
        hu: 'Előfizetés',
        gradient: true,
      },
    ],
    name: {
      en: 'Find the best converting headlines automatically.',
      hu: 'Találd meg a legjobban konvertáló címsorokat automatikusan.',
    },
    usage: {
      en: 'Forget time-consuming A/B testing. Just pick the elements on your website you want to test, and let our AI do the rest of the job.',
      hu: 'Felejtsd el az időigényes A/B tesztelést. Egyszerűen csak válaszd ki a tesztelni kívánt elemeket az oldaladon és hagyd, hogy az AI elvégezze a munka többi részét.',
    },
    image: 'fix-tactics/auto-ab-test.jpg',
    content: {
      en: 'https://www.optimonk.com/templates/use-cases/smart-a-b-testing-64e7305c367ae400259fad92',
      hu: 'https://www.optimonk.hu/templates/use-cases/intelligens-a-b-teszteles-64e7305c367ae400259fad92',
    },
    hasSelectHandler: true,
  },
  {
    tags: [
      {
        en: 'Product Page Optimizer',
        hu: 'Termékoldal Optimalizáló',
      },
      {
        en: 'Upgrade',
        hu: 'Előfizetés',
        gradient: true,
      },
    ],
    name: {
      en: 'Optimize your product pages with AI-powered copy.',
      hu: 'Optimalizáld termékoldaldaidat AI-alapú szövegekkel.',
    },
    usage: {
      en: 'Use the power of AI to create better headlines, descriptions, and benefit lists, and run A/B tests to tailor the ideal product page. And do it for thousands of product pages – parallelly.',
      hu: 'Használd az AI erejét, hogy jobb főcímeket, címsorokat, leírásokat és termékelőny listákat készíthess, majd futass A/B teszteket a tökéletes termékoldal kialakításához. Tedd meg ezt akár több ezer oldallal – egyszerre.',
    },
    image: 'fix-tactics/ppo.jpg',
    content: {
      en: 'https://www.optimonk.com/templates/use-cases/smart-product-page-optimizer-64e743ce3a159c002336ca75',
      hu: 'https://www.optimonk.hu/templates/use-cases/intelligens-termekoldal-optimalizalo-64e743ce3a159c002336ca75',
    },
    hasSelectHandler: true,
  },
];

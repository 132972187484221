<template lang="pug">
.template-preview-benchmarks
  .template-preview-benchmarks-conversion-rate
    span.template-preview-benchmarks-conversion-rate-text {{ $t('avgConversion') }}:
    span.template-preview-benchmarks-conversion-rate-value {{ conversionRate }}
</template>
<script>
  import { getConversionRate } from '@/util';

  export default {
    props: {
      template: {
        type: Object,
      },
    },
    computed: {
      conversionRate() {
        return getConversionRate(this.template.conversionRate);
      },
    },
  };
</script>
<style lang="sass" scoped>
  @import '@/sass/variables/variables'
  .template-preview-benchmarks
    color: $om-gray-700
    line-height: 1.5
    font-size: 1rem
    &-conversion-rate
      &-value
        color: $om-gray-800
        font-weight: 500
        padding-left: .25rem
</style>

import { initMobileStyles } from '../util';

export default (element) => {
  element.style.textShadow = element.style.textShadow || {};
  element.style.textShadow.type = element.style.textShadow.type || 'none';
  element.style.textShadow.color = element.style.textShadow.color || '#000';

  initMobileStyles(element, ['manualPadding', 'manualMargin', 'border', 'shadow']);

  return element;
};

import DeleteTemplate from './DeleteTemplate.vue';
import Duplicate from './Duplicate.vue';
import EditSettings from './EditSettings.vue';
import EditTemplate from './EditTemplate.vue';
import MakeFeatured from './MakeFeatured.vue';
import MakeItDraft from './MakeItDraft.vue';
import MakeItUnlisted from './MakeItUnlisted.vue';
import MakeSeasonal from './MakeSeasonal.vue';
import OpenLink from './OpenLink.vue';
import Publish from './Publish.vue';
import Minus from './Minus.vue';

export {
  DeleteTemplate,
  Duplicate,
  EditSettings,
  EditTemplate,
  MakeFeatured,
  MakeItDraft,
  MakeItUnlisted,
  MakeSeasonal,
  OpenLink,
  Publish,
  Minus,
};

import { debounce } from 'lodash-es';
import runtimeConfig from '@/config/runtime';
import WebFontLoader from 'webfontloader';
import TemplateFrame from '@/components/Template/TemplateFrame.vue';
import { getAccountIdFromCookie } from '@/util';

const CDN_URL = runtimeConfig.VUE_APP_CDN_URL;

const splitFont = (font) => {
  const [name, sizesString = ''] = font.split(':');
  return {
    name,
    sizes: sizesString.split(','),
  };
};

const getUniqueFontsMap = (all) => {
  const fonts = {};
  all.forEach(({ name, sizes }) => {
    if (!fonts[name]) {
      fonts[name] = sizes;
    }
    if (fonts[name]) {
      const loadedSizes = Array.from(new Set(fonts[name]).values());
      fonts[name] = Array.from(new Set([...sizes, ...loadedSizes]).values());
    }
  });
  return fonts;
};

const getFontsDelta = (reduced, loaded, loading) => {
  const prefiltered = !loaded.length
    ? reduced
    : reduced.filter((fontString) => loaded.indexOf(fontString) === -1);
  return prefiltered.filter((fontString) => loading.indexOf(fontString) === -1);
};

const reduceFontsList = (currentList, loaded = [], loading = []) => {
  const all = [...currentList.map(splitFont), ...loaded.map(splitFont), ...loading.map(splitFont)];
  const fonts = getUniqueFontsMap(all);

  const reduced = Object.entries(fonts).map(([key, value]) => (value[0] ? `${key}:${value}` : key));

  const delta = getFontsDelta(reduced, loaded, loading);

  return { reduced, delta };
};

const alreadyLoaded = {
  google: [],
  custom: [],
};

const loadingDelta = {
  google: [],
  custom: [],
};

export default {
  components: { TemplateFrame },
  data: () => ({
    loadFontsFromAccount: 44,
  }),
  created() {
    this.loadFontsFromAccount = getAccountIdFromCookie();
  },
  methods: {
    addUsedFonts(config, delta) {
      if (!delta || !delta.length) return;
      delta[delta.length - 1] += '&display=swap';
      config.google = {
        families: delta,
      };
    },
    addCustomFonts(config, delta) {
      if (!delta || !delta.length) return;
      config.custom = {
        families: delta,
        urls: delta.map(
          (font) => `${CDN_URL}/customFonts/${this.loadFontsFromAccount}/${font}/${font}.css`,
        ),
      };
    },
    handleFonts(usedFonts, customFonts) {
      const { reduced: gFull, delta: gDelta } = reduceFontsList(
        usedFonts,
        alreadyLoaded.google,
        loadingDelta.google,
      );
      const { reduced: cFull, delta: cDelta } = reduceFontsList(
        customFonts,
        alreadyLoaded.custom,
        loadingDelta.custom,
      );
      loadingDelta.google = gDelta;
      loadingDelta.custom = cDelta;

      const WebFontConfig = {
        classes: false,
        context: window,
        active() {
          alreadyLoaded.google = gFull;
          alreadyLoaded.custom = cFull;
          loadingDelta.google = [];
          loadingDelta.custom = [];
        },
      };
      this.addUsedFonts(WebFontConfig, gDelta);
      this.addCustomFonts(WebFontConfig, cDelta);
      if (WebFontConfig.google || WebFontConfig.custom) WebFontLoader.load(WebFontConfig);
    },
    parseAssets(assetsLists) {
      const usedFonts = [];
      const customFonts = [];
      assetsLists.forEach((assetNode) => {
        usedFonts.push(...JSON.parse(assetNode.getAttribute('data-fonts')));
        customFonts.push(...JSON.parse(assetNode.getAttribute('data-custom-fonts')));
      });
      return { usedFonts, customFonts };
    },
    onContentLoaded: debounce(function () {
      const assetsLists = Array.from(document.querySelectorAll('.om-asset-helper'));
      const { usedFonts, customFonts } = this.parseAssets(assetsLists);
      this.handleFonts(usedFonts, customFonts);
    }, 150),
  },
};

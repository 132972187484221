import { mapPopupPositionToTeaser } from '../../utils';
import defaultOverlayBackground from './defaults/overlayBackground';
import settingsHelper from './v2/settings/index';

export default (settings) => {
  settings.animation.backgroundAnimation = settings.animation.backgroundAnimation || 'none';
  settings.animation.backgroundAnimationPosition =
    settings.animation.backgroundAnimationPosition || 'popup';
  settings.tab.animation = settings.tab.animation || {};
  settings.tab.animation.switch = settings.tab.animation.switch || {};
  settings.tab.animation.switch.type = settings.tab.animation.switch.type || '';
  settings.tab.animation.attentionSeeker = settings.tab.animation.attentionSeeker || {};
  settings.tab.animation.attentionSeeker.type = settings.tab.animation.attentionSeeker.type || '';
  settings.tab.animation.attentionSeeker.freq = settings.tab.animation.attentionSeeker.freq || 3;

  const defaultTeaserPosition = mapPopupPositionToTeaser(settings.overlay.position);

  if (typeof settings.tab.positions.position === 'number') {
    settings.tab.positions.position = 'bottom-left';
  } else {
    settings.tab.positions.position = settings.tab.positions.position || defaultTeaserPosition;
  }

  settings.tab.positions.position =
    typeof settings.tab.positions.position === 'number'
      ? 'top-left'
      : settings.tab.positions.position;

  settings.overlay.fitToScreen = settings.overlay.fitToScreen || false;
  settings.overlay.alignItems = settings.overlay.alignItems || 'center';
  settings.overlay.mobilePosition = settings.overlay.mobilePosition || 'center';

  settings.overlay.animation = settings.overlay.animation || {
    type: null,
    mode: 'cannon',
    page: 0,
  };

  if (!settings.overlay.background) {
    settings.overlay.background = {
      ...defaultOverlayBackground,
      color: settings.overlay.backgroundColor,
    };
  }

  if (!settings.overlay.margin) {
    settings.overlay.margin = settingsHelper.margin(settings.overlay.margin);
  }

  return settings;
};

import { initMobileStyles } from '../util';

export default (element, _, whiteLabel) => {
  if (
    whiteLabel &&
    whiteLabel.isSubUser &&
    element.data.redirectUrl === 'https://www.optimonk.com'
  ) {
    element.data.redirectUrl = `https://${whiteLabel.domainBase}`;
  }

  if (element.style.shadow.type === null) {
    element.style.shadow.color = null;
  }

  initMobileStyles(element, ['manualMargin', 'size']);
};

import { get as _get } from 'lodash-es';

const initBorder = (style, type, global) => {
  type = (type || '').toLowerCase();
  const border = _get(style, 'border');
  let subPath = `style.${type}`;

  if (['radio', 'checkbox'].includes(type)) {
    subPath = 'style.input-picker';
  } else if (['input', 'textarea'].includes(type)) {
    subPath = 'style.inputs';
  }

  if (border) {
    const { selectedBorder } = border;
    const globalBorder = _get(global, `${subPath}.border`);
    const globalBorderWidth = _get(globalBorder, 'width');
    const globalBorderColor = _get(globalBorder, 'color');

    if (selectedBorder === 'no' || !selectedBorder) {
      const isGlobalBorderSet =
        globalBorder && globalBorder.selectedBorder && globalBorder.selectedBorder !== 'no';
      border.width = isGlobalBorderSet ? border.width || globalBorderWidth || 2 : 2;
      border.color = isGlobalBorderSet ? border.color || globalBorderColor : 0;
    } else {
      const globalSelected = globalBorder ? globalBorder.selectedBorder !== 'no' : false;
      const realFallbackColor = globalSelected ? globalBorderColor : 0;
      border.width = border.width || globalBorderWidth || 2;
      border.color = border.color || realFallbackColor;
    }

    style.border = border;
  }

  return style;
};

export default (element, template) => {
  const type = element.type.toLowerCase().slice(2);

  element.style = initBorder(element.style, type, template);

  return element;
};

<template lang="pug">
.tactic-slider(ref="slider")
  .tactic-slider-body
    .tactic-slider-body-swiper(v-swiper:swiperRef="swiperOptions")
      .swiper-wrapper
        slot(name="tactics-slider-cards")
    .swiper-button-next(tabindex="0" role="button" :id="`swiper-button-next-${sliderId}`")
    .swiper-right-gradient
    .swiper-button-prev(tabindex="0" role="button" :id="`swiper-button-prev-${sliderId}`")
    .swiper-left-gradient
    .swiper-pagination
</template>

<script>
  import { Swiper as SwiperClass, Navigation, Autoplay } from 'swiper/swiper.esm';
  import getAwesomeSwiper from 'vue-awesome-swiper/dist/exporter';
  import { nanoid } from 'nanoid';
  import 'swiper/swiper-bundle.min.css';

  SwiperClass.use([Navigation, Autoplay]);
  const SwiperComponent = getAwesomeSwiper(SwiperClass);

  const { directive } = getAwesomeSwiper(SwiperClass);

  export default {
    components: { SwiperComponent },
    directives: {
      swiper: directive,
    },
    mixins: [],
    props: {
      slidesPerView: { default: 3.5 },
      slidesPerViewTabletLg: { default: 3.5 },
      slidesPerViewTabletSm: { default: 2.5 },
      slidesPerViewMobile: { default: 1.5 },
      idPostfix: { type: String, default: null },
    },

    data() {
      return {
        sliderId: nanoid(8),
      };
    },

    computed: {
      swiperOptions() {
        return {
          allowTouchMove: true,
          slidesPerView: this.slidesPerViewMobile,
          spaceBetween: 20,
          navigation: {
            nextEl: `#swiper-button-next-${this.sliderId}`,
            prevEl: `#swiper-button-prev-${this.sliderId}`,
          },
          breakpoints: {
            768: {
              slidesPerView: this.slidesPerViewTabletSm,
              spaceBetween: 20,
            },
            1200: {
              slidesPerView: this.slidesPerViewTabletLg,
              spaceBetween: 20,
            },
            1400: {
              slidesPerView: this.slidesPerView,
              spaceBetween: 20,
            },
          },
        };
      },
    },
  };
</script>

<style lang="sass">
  @import '@/sass/variables/_colors.sass'
  .swiper-button-disabled
    & ~ .swiper-right-gradient,
    & ~ .swiper-left-gradient
      opacity :0
  .tactic-slider
    .swiper
      &-right-gradient,
      &-left-gradient
        background: linear-gradient(270deg, rgba(255, 255, 255, 0.7) 0%, rgba(255, 255, 255, 0) 100%)
        width: 2rem
        height: 100%
        position: absolute
        top: 0
        right: 0
        z-index: 9
      &-left-gradient
        background: linear-gradient(90deg, rgba(255, 255, 255, 0.7) 0%, rgba(255, 255, 255, 0) 100%)
        left: 0
    .swiper-slide
      cursor: pointer
    .swiper-container
      padding: 16px 0 40px
      width: 100%!important
    .swiper-button-next, .swiper-button-prev
      width: 2.75rem
      height: 2.75rem
      background: white
      border-radius: 100%
      box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1)
      &:after
        color: $om-gray-700
        font-size: 1rem
      &.swiper-button-disabled
        opacity: 0
</style>

import { nilMergeDeep, isDefined } from '../../../utils';
import { initMobileStyles } from '../util';

const initFontStyle = (name, style, global) => {
  style[name] = style[name] || {};

  style[name].lineHeight = isDefined(style[name].lineHeight) ? style[name].lineHeight : 1;
  style[name].fontWeight = isDefined(style[name].fontWeight)
    ? style[name].fontWeight
    : style[name].textWeight
    ? 700
    : 300;

  style[name].fontFamily = isDefined(style[name].fontFamily)
    ? style[name].fontFamily
    : global.canvas.fontFamily;
  style[name].fontSize = isDefined(style[name].fontSize)
    ? style[name].fontSize
    : global.text.fontSize;
  style[name].color = isDefined(style[name].color) ? style[name].color : global.text.color;
  style[name].verticalSpacing = isDefined(style[name].verticalSpacing)
    ? style[name].verticalSpacing
    : 10;
};

export default (element, template) => {
  if (['checkbox', 'radio'].includes(element.data.form.customSettings.type)) {
    element.data = nilMergeDeep(element.data, {
      displayType: 'normal',
      feedback: {
        question: '',
      },
    });

    element.style = nilMergeDeep(element.style, {
      checkedColor: '#a2a2a2',
      feedback: {},
      alignPicker: 'center',
    });

    initFontStyle('question', element.style.feedback, template.style);
  }

  if (typeof window !== 'undefined' && element.data.form.customSettings.type === 'date') {
    if (window.om.i18n.locale === 'hu' && element.data.format === 'JMY') {
      element.data.format = 'dMY';
    }
  }

  initMobileStyles(element);

  return element;
};

export default {
  props: {
    rootMargin: {
      type: String,
    },
  },
  data: () => ({
    intersectionRoot: null,
    intersectionObserverRootMargin: '0px',
    intersectionObserver: null,
    observed: new Set(),
  }),
  computed: {
    ioRootMargin() {
      return this.rootMargin || this.intersectionObserverRootMargin;
    },
  },
  methods: {
    initIntersectionObserver(elements = []) {
      this.intersectionObserver = new IntersectionObserver(
        (entries) => {
          entries.forEach((entry) => {
            this.handleIntersection(entry);
          });
        },
        {
          root: this.intersectionRoot || document.body,
          rootMargin: this.ioRootMargin,
        },
      );
      elements.forEach((element) => {
        this.observed.add(element);
        this.intersectionObserver.observe(element);
      });
    },

    handleIntersection(entry) {
      const component = entry.target && entry.target.__comp__;
      if (!component || !this.isObservableComponent(component)) return;
      if (entry.isIntersecting) {
        component.onIntersecting();
      } else {
        component.onNotIntersecting();
      }
    },

    isObservableComponent(component) {
      return component.onIntersecting && component.onNotIntersecting;
    },

    addObservable(element) {
      if (!this.intersectionObserver) this.initIntersectionObserver();
      if (!this.observed.has(element)) {
        this.observed.add(element);
        this.intersectionObserver.observe(element);
      }
    },
  },
};

import { get as _get } from 'lodash-es';
import { websafeFonts, isDefined } from '@/editor/util';

export const campaignScope = '#om-campaign-0';
export const BASE_FONT_SIZE = 16;

export const isInCenterPosition = (style) => style.position === 5;

export const getColorCss = (target, templateStyle) => {
  let value = target;

  // if value is integer
  if (value > -2) {
    if (value === -1 || value === '-1') {
      value = templateStyle.palette.mainColor;
    } else {
      value = templateStyle.palette.secondaryColors[value];
    }
  }
  return value;
};

export const getColorCssV2 = (target, template) => {
  return template.palette[target];
};

export const cssFourValueResolve = (selected, value, suffix = '') => {
  // const positions = ['left', 'top', 'right', 'bottom']
  const positions = ['top', 'right', 'bottom', 'left']; // order for correct output
  const type = selected.split('-');
  const fullValue = `${value}${suffix}`;
  if (selected === 'full') return fullValue;

  const result = [];
  positions.forEach((position) => {
    const needToAdd = type.includes(position);
    result.push(needToAdd ? fullValue : 0);
  });
  return result.join(' ');
};

export const setColorCss = (target, templateStyle, cssProperty, rules, important = false) => {
  if (target !== undefined) {
    const value = getColorCss(target, templateStyle);
    if (important) {
      rules.push([cssProperty, `${value} !important`]);
    } else {
      rules.push([cssProperty, value]);
    }
  }
};

export const pixelToEm = (value, data, style, templateStyle, subPath) => {
  let multiplier = 1;
  if (
    data &&
    (data.type === 'inputs' ||
      subPath === 'inputs' ||
      subPath === 'button' ||
      data.type === 'button' ||
      subPath === 'text' ||
      data.type === 'text' ||
      subPath === 'input-picker')
  ) {
    const isButton = subPath === 'button' || data.type === 'button';
    let localFontSize;

    if (isButton) {
      localFontSize = style.button.fontSize;
    } else {
      localFontSize = style.fontSize;
    }

    if (subPath === 'text') {
      localFontSize = style.text.fontSize;
    } else if (data.type === 'text') {
      localFontSize = templateStyle.text.fontSize;
    }

    if (!localFontSize && (style.fontSize === null || style.fontSize === undefined)) {
      if (subPath === 'inputs' || data.type === 'inputs') {
        localFontSize = templateStyle.inputs.fontSize;
      } else if (isButton) {
        localFontSize = templateStyle.button.fontSize;
      } else if (subPath === 'input-picker') {
        localFontSize = templateStyle['input-picker'].fontSize;
      }
    }

    multiplier = data.divider ? 1 : BASE_FONT_SIZE / localFontSize;

    if (value !== null) {
      return (value / BASE_FONT_SIZE) * multiplier;
    }
    return value;
  }
  if (data && data.type === 'countdown') {
    multiplier = BASE_FONT_SIZE / style.countdown.fontSize;
    return (value / BASE_FONT_SIZE) * multiplier;
  }
  if (subPath === 'tab') {
    multiplier = BASE_FONT_SIZE / templateStyle.tab.fontSize;
    return (value / BASE_FONT_SIZE) * multiplier;
  }
  if (value !== null) {
    return value / BASE_FONT_SIZE;
  }
  return value;
};

export const margin = function () {
  const values = arguments.map((x) => pixelToEm(x));
  return `margin:${values.join('em ')};`;
};

export const getColorForGradient = (value, templateStyle, type, prop) => {
  if (!isDefined(value)) {
    const globalValue = _get(templateStyle, `${type}.background.${prop}`);
    return getColorCss(globalValue, templateStyle);
  }
  return value;
};

export const getBackground = (style, templateStyle, template, needGrandientColors = true) => {
  const rules = [];
  const background = _get(style, 'background');
  if (!background) return;

  const {
    type: backgroundType,
    gradientType,
    gradientCenter,
    linearDirection,
    imageId,
  } = background;
  let color1 = getColorCss(background.color, templateStyle);
  if (backgroundType === 'transparent') {
    rules.push(['background', 'transparent']);
  } else if (backgroundType === 'solid-fill') {
    rules.push(['background', color1]);
  } else if (backgroundType === 'gradient') {
    let color2 = getColorCss(background.color2, templateStyle);
    // fetch global color if needed
    if (needGrandientColors) {
      color1 = getColorForGradient(color1, templateStyle, 'color');
      color2 = getColorForGradient(color2, templateStyle, 'color2');
    }

    if (gradientType === 'linear') {
      rules.push(['background', `linear-gradient(${linearDirection}deg,${color1},${color2})`]);
    } else if (gradientCenter !== 'circle at center') {
      rules.push(['background', `linear-gradient(${gradientCenter},${color1},${color2})`]);
    } else if (gradientCenter === 'circle at center') {
      rules.push(['background', `radial-gradient(${gradientCenter},${color1},${color2})`]);
    }
  } else if (backgroundType === 'image') {
    const image = template.images.find((r) => r._id.toString() === imageId);
    if (image) rules.push(['background', `url(${image.url})`]);

    const imageRepeat = _get(background, 'imageRepeat');
    if (imageRepeat) rules.push(['background-repeat', imageRepeat]);

    const imagePosition = _get(background, 'imagePosition');
    const imageAlign = _get(background, 'imageAlign') || 'center';
    const imageHorizontalAlign = _get(background, 'imageHorizontalAlign') || 'center';
    if (imagePosition === 'full') {
      rules.push(['background-size', 'auto auto']);
    } else if (imagePosition === 'contain') {
      rules.push(['background-size', imagePosition]);
      rules.push(['background-position', imageAlign]);
    } else if (imagePosition === 'cover') {
      rules.push(['background-size', imagePosition]);
      rules.push(['background-position-x', imageHorizontalAlign]);
    } else if (imagePosition && imagePosition !== '') {
      rules.push(['background-size', imagePosition]);
    }
    rules.push(['background-color', color1]);
  }
  return rules;
};
// TODO move all logic from CssHelper
export const getInputSize = (style, data, templateStyle) => {
  const rules = [];
  const fixedType = 'inputs';
  // Currently not listed, so commented out
  // if (style[type].size === 'fluid') {
  //   rules.push(['display', 'inline-block'])
  //   rules.push(['min-width', '100px'])
  // } else
  if (style.size === '100w') {
    rules.push(['width', '100%']);
  } else if (style.size === 'manual') {
    rules.push([
      'width',
      `${pixelToEm(style.globalWidth, data, style, templateStyle, fixedType)}em`,
    ]);
  }
  const textAlign = style.textAlign;
  if (textAlign) {
    rules.push(['text-align', textAlign]);
  }
  rules.push([
    'height',
    `${pixelToEm(style.globalHeight, data, style, templateStyle, fixedType)}em`,
  ]);
  return rules;
};

// const fontFormatOptions = quillOptions.fontFormatOptions.map(o => {
//   return {
//     key: o.label, value: o.value
//   }
// })

const _store = () => global.__vue_store__ || window.om.store;
const websafes = {};
websafeFonts.forEach(({ key, family }) => {
  websafes[key] = family;
});

const _getThemeKitFontIndex = (value) => {
  return parseInt(value.replace('om-font-', ''), 10) - 1;
};

export const findSelectedFamily = (value) => {
  let returnValue = '';
  const store = _store();
  if (Object.keys(websafes).includes(value)) return websafes[value];
  if (!store) return returnValue;
  store.getters.installedFonts.forEach((font) => {
    if (font.key === value) returnValue = font.family;
    if (value.startsWith('om-font')) {
      const index = _getThemeKitFontIndex(value);
      returnValue = `var(--om-font-${index})`;
    }
  });
  return returnValue;
};

export const getFontFamilies = (scope, inDocker) => {
  const rules = [];
  const store = _store();
  if (!store) return '';
  store.getters.installedFonts.forEach(({ key, family }) => {
    if ((inDocker && store.state.usedFonts.includes(key)) || !inDocker) {
      let fontFamilyRule = `${scope} .ql-font-${key} {font-family:"${family}"}`;
      if (!inDocker)
        fontFamilyRule += ` .ql-picker-item[data-value="${key}"] {font-family:"${family}"}`;
      rules.push(fontFamilyRule);
    }
  });
  return rules.join('\n');
};

export const ruleJoin = (rules) =>
  rules
    .map((rule) => (rule.length === 2 ? `${rule.join(':')};` : `${rule[0]}:${rule[1]}!important;`))
    .join('\n');

export const shadowOptions = {
  none: '0px 0px',
  normal: '4px 4px 4px 0',
  medium: '8px 8px 16px 0',
  large: '16px 16px 32px 0',
};

export const textShadowOptions = {
  none: 'none',
  light: '0px 10px 20px',
  medium: '2px 2px 1px',
  large: '2px 4px 3px',
};

export const getShadowRules = ({ color, type }, global) => {
  const rules = [];
  const colorValue = getColorCss(color, global);
  if (shadowOptions[type] && colorValue) {
    rules.push(['box-shadow', `${shadowOptions[type]} ${colorValue}`]);
  }
  return rules;
};

export const getBorderRadius = (type, value, suffix = '') => {
  const valueWithSuffix = `${value}${suffix}`;

  const types = {
    full: `${valueWithSuffix} ${valueWithSuffix} ${valueWithSuffix} ${valueWithSuffix}`,
    top: `${valueWithSuffix} ${valueWithSuffix} 0 0`,
    bottom: `0 0 ${valueWithSuffix} ${valueWithSuffix}`,
    left: `${valueWithSuffix} 0 0 ${valueWithSuffix}`,
    right: `0 ${valueWithSuffix} ${valueWithSuffix} 0`,
  };

  return types.hasOwnProperty(type) ? types[type] : 0;
};

export const getBorder = (style, global) => {
  const rules = [];
  const color = getColorCss(style.border.color || 0, global); // 0 is the first secondary color since -1 is the main color
  const width = parseInt(style.border.width || 2, 10);
  const { selectedBorder, type, radiusType, radius } = style.border;

  if (width) {
    rules.push(['border-color', color, true]);
    rules.push(['border-style', type, true]);
    const widthEm = width / BASE_FONT_SIZE;
    rules.push(['border-width', cssFourValueResolve(selectedBorder, widthEm, 'em'), true]);
  }
  const borderRadius = getBorderRadius(radiusType, radius / BASE_FONT_SIZE, 'em');
  if (borderRadius) rules.push(['border-radius', borderRadius]);

  return rules;
};

export const getFontRules = (style, templateStyle) => {
  const rules = [];
  rules.push(['font-family', findSelectedFamily(style.fontFamily) || style.fontFamily]);
  rules.push(['font-size', `${pixelToEm(style.fontSize)}em`]);
  rules.push(['color', getColorCss(style.color, templateStyle)]);
  if (style.textAlign && style.textAlign !== 'left') rules.push(['text-align', style.textAlign]);
  if (style.fontStyle) {
    if (style.fontStyle.textWeight) rules.push(['font-weight', 'bold']);
    if (style.fontStyle.fontItalic) rules.push(['font-style', 'italic']);
    if (style.fontStyle.textDecoration) rules.push(['text-decoration', 'underline']);
    if (style.fontStyle.textStrikeThrough) rules.push(['text-decoration', 'line-through']);
  } else {
    if (style.textWeight) rules.push(['font-weight', 'bold']);
    if (style.fontItalic) rules.push(['font-style', 'italic']);
    if (style.textDecoration) rules.push(['text-decoration', 'underline']);
  }

  if (style.lineHeight) {
    rules.push(['line-height', style.lineHeight]);
  }

  if (style.fontWeight) {
    rules.push(['font-weight', style.fontWeight]);
  }

  return rules;
};

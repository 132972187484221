<template lang="pug">
.om-tactic-advanced-recommendation-card-mininal(
  :class="{ 'om-wizard-brandkit': true }"
  :data-track-property="`change:tactic.learn|item:${getItemNameForTracking}`"
)
  .om-tactic-advanced-recommendation-card-mininal-preview
    .om-tactic-advanced-recommendation-card-mininal-preview-overlay(
      @click="learnMore(tactic, 'advanced-tactic-card-minimal-learnMore')"
    )
    img(v-if="hasImage" :src="image")

  .om-tactic-advanced-recommendation-card-mininal-content(
    @click="learnMore(tactic, 'advanced-tactic-card-minimal-learnMore')"
  )
    .om-tactic-advanced-recommendation-card-mininal-content-name {{ tactic.name[$i18n.locale] }}
</template>

<script>
  import tacticsMixin from './tacticsMixin';

  export default {
    mixins: [tacticsMixin],
    props: {
      index: {
        type: Number,
        require: true,
      },
      tactic: {
        type: Object,
        require: true,
      },
    },
  };
</script>

<style lang="sass">
  @import '@/sass/variables/_colors.sass'
  .om-tactic-advanced-recommendation-card-mininal

    &-preview
      position: relative
      transition: .3s
      z-index: 9

      display: flex
      align-items: center
      justify-content: center
      border-radius: 0.25rem
      border: 1px solid #E3E8ED
      aspect-ratio: 157 / 89
      background-color: #FFF7F2
      padding: 1rem

      img
        height: 100%

      &-overlay
        position: absolute
        width: 100%
        height: 100%
        z-index: 999
        opacity: 0
        transition: .3s
        display: flex
        align-items: center
        justify-content: center
        background: rgba(80, 87, 99, 0.1)
        cursor: pointer
        overflow: hidden
        border-radius: 0.25rem

      &:hover
        .om-tactic-advanced-recommendation-card-mininal-preview-overlay
          opacity: 1
          overflow: hidden
          border-top-left-radius: 8px
          border-bottom-left-radius: 8px

    &-content
      cursor: pointer
      padding: 10px 0

      &:hover
        color: $om-orange-500

      &-name
        font-size: 1rem
        margin-bottom: 0.5rem

      &-targeting
        color: $om-gray-700
        font-size: 0.8rem
        line-height: 1.5

      &-controls
        display: flex
        margin-top: auto
        justify-content: flex-end
</style>

<template lang="pug">
.brand-template-progress-item(:class="completed ? 'brand-template-progress-item-completed' : ''")
  .brand-template-progress-item-circle
    img(v-if="completed"
        :src="require('@/assets/admin/svg/icons/check.svg')")
    span(v-else) {{ index }}
  .brand-template-progress-item-text
    slot
  img.brand-template-progress-item-separator(v-if="!last" :src="require('@/assets/admin/svg/icons/chevron-right.svg')")
</template>
<script>
  export default {
    props: {
      index: {
        type: Number,
        default: 1,
      },
      completed: {
        type: Boolean,
        default: false,
      },
      last: {
        type: Boolean,
        default: false,
      },
    },
  };
</script>

<style lang="sass">
  .brand-template-progress-item
    display: flex
    align-items: center

    &-circle
      border-radius: 100%
      background: rgba(116, 125, 131, 0.3)
      color: white
      font-size: 0.6875rem
      display: flex
      align-items: center
      justify-content: center
      height: 1.375rem
      width: 1.375rem
      font-weight: bold
      line-height: 1

    &-text
      margin-left: 0.625rem
      color: rgba(116, 125, 131, 0.3)

    &-separator
      margin: 0 1.5rem

    &-completed
      .brand-template-progress-item-circle
        background: #ED5A29
      .brand-template-progress-item-text
        color: #ED5A29
</style>

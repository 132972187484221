import { nilMergeDeep } from '../../../utils';
import { initMobileStyles } from '../util';

export default (element) => {
  element.style = nilMergeDeep(element.style, {
    manualPadding: {
      allSides: true,
      top: 5,
      left: 5,
      bottom: 5,
      right: 5,
    },
    background: {
      color: -1,
      color2: '#fff',
      type: 'transparent',
      gradientType: 'linear',
      linearDirection: 45,
      imagePosition: 'cover',
      imageAlign: 'center',
      imageHorizontalAlign: 'center',
      imageRepeat: 'no-repeat',
    },
  });

  initMobileStyles(element, ['manualMargin', 'manualPadding']);

  return element;
};

import { isDefined } from '../../../utils';
import { initMobileStyles } from '../util';

const initInputs = (style, global) => {
  style.inputs = isDefined(style.inputs.background) ? style.inputs : global.inputs;
  style.inputs.fontFamily = isDefined(style.inputs.fontFamily)
    ? style.inputs.fontFamily
    : global.canvas.fontFamily;
  return style;
};

export default (element, template, whiteLabel) => {
  element.data.type = 'social';
  element.style = initInputs(element.style, template.style);
  element.style.color = element.style.color || element.style.socialColor || '#222222';
  element.style.fontSize = element.style.fontSize || element.style.linkFontSize || 20;
  delete element.style.socialColor;
  delete element.style.linkFontSize;

  if (
    whiteLabel &&
    whiteLabel.isSubUser &&
    element.data.socialShare.url === 'http://facebook.com/OptiMonk'
  ) {
    element.data.socialShare.url = `https://facebook.com/${whiteLabel.brandName}`;
  }

  initMobileStyles(element);

  return element;
};

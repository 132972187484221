import countdown from './countdown';
import coupon from './coupon';
import inputs from './inputs';
import scratchcard from './scratchcard';
import social from './social';
import text from './text';
import product from './product';
import feedback from './feedback';
import image from './image';
import button from './button';
import divider from './divider';
import customhtml from './customhtml';
import pickapresent from './pickapresent';
import common from './common';
import spacer from './spacer';
import luckywheel from './luckywheel';

export default {
  coupon,
  countdown,
  social,
  inputs,
  scratchcard,
  text,
  feedback,
  image,
  button,
  product,
  divider,
  customhtml,
  pickapresent,
  common,
  spacer,
  luckywheel,
};

import { isDefined, translate } from '../../../../utils';

export const isObject = (obj) =>
  (typeof obj === 'object' && obj !== null) || typeof obj === 'function';

export const initWithDefaults = (value, defaults) => {
  if (value) {
    const result = value;

    if (defaults) {
      // eslint-disable-next-line no-restricted-syntax
      for (const [key, value] of Object.entries(defaults)) {
        if (!isDefined(result[key])) {
          result[key] = value;
        } else if (isObject(result[key]) && !Array.isArray(result[key])) {
          result[key] = initWithDefaults(result[key], value);
        }
      }
    }

    return result;
  }

  return defaults;
};

export const initFeedbackOptions = (displayType, isSSR) => {
  if (displayType === 'stars') {
    const options = [];
    for (let rating = 1; rating <= 5; rating++) {
      options.push({
        key: translate(`feedbackOptions.${rating}`, isSSR),
        value: rating,
        action: 'none',
        jumpTo: null,
      });
    }

    return options;
  }
  if (displayType === 'yesno') {
    return [
      {
        key: translate('feedbackOptions.yes', isSSR),
        value: 'yes',
        action: 'none',
        jumpTo: null,
        image: {},
      },
      {
        key: translate('feedbackOptions.no', isSSR),
        value: 'no',
        action: 'none',
        jumpTo: null,
        image: {},
      },
    ];
  }
  if (displayType === 'smiley') {
    return [
      {
        key: translate('feedbackOptions.good', isSSR),
        value: 'good',
        action: 'none',
        jumpTo: null,
        image: {},
      },
      {
        key: translate('feedbackOptions.neutral', isSSR),
        value: 'neutral',
        action: 'none',
        jumpTo: null,
        image: {},
      },
      {
        key: translate('feedbackOptions.bad', isSSR),
        value: 'bad',
        action: 'none',
        jumpTo: null,
        image: {},
      },
    ];
  }

  return [];
};

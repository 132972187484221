import { get as _get } from 'lodash-es';
import { translate, isDefined } from '../../../utils';

const propertyValue = (obj, path, defaultValue) => {
  const value = _get(obj, path, null);
  return isDefined(value) ? value : defaultValue;
};

const initData = (data) => {
  data = data || {};
  data.bg = data.bg || {};

  data.message = isDefined(data.message) ? data.message : translate('scratchCard.message');
  data.bg.win = propertyValue(data, 'bg.win', {});
  data.bg.lose = propertyValue(data, 'bg.lose', {});

  return data;
};

const initStyle = (style) => {
  style = style || {};
  style.background = style.background || {};

  style.background.color = propertyValue(style, 'background.color', -1);
  style.background.color2 = propertyValue(style, 'background.color2', '#fff');
  style.background.type = propertyValue(style, 'background.type', 'gradient');
  style.background.gradientType = propertyValue(style, 'background.gradientType', 'linear');
  style.background.linearDirection = propertyValue(style, 'background.linearDirection', 45);
  style.background.imagePosition = propertyValue(style, 'background.imagePosition', 'cover');
  style.background.imageAlign = propertyValue(style, 'background.imageAlign', 'center');
  style.background.imageHorizontalAlign = propertyValue(
    style,
    'background.imageHorizontalAlign',
    'center',
  );
  style.background.imageRepeat = propertyValue(style, 'background.imageRepeat', 'no-repeat');

  return style;
};

export default (element) => {
  element.style.scratchCard = initData(element.style.scratchCard);
  element.style.scratchCard.style = initStyle(element.style.scratchCard.style);
  return element;
};

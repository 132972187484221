const _clone = (v) => JSON.parse(JSON.stringify(v));

export const pickAPresentOptionsDef = [
  { weigth: 1, title: 'First', couponCode: 'FIRST100' },
  { weigth: 0, title: '', couponCode: '' },
  { weigth: 0, title: '', couponCode: '' },
  { weigth: 0, title: '', couponCode: '' },
  { weigth: 0, title: '', couponCode: '' },
];

export default (settings) => {
  return settings || _clone(pickAPresentOptionsDef);
};

import { nilMergeDeep, translate, isDefined } from '../../../utils';
import { initMobileStyles } from '../util';

const _c = (v) => JSON.parse(JSON.stringify(v));

const initData = (data) => {
  data.presentCount = data.presentCount || 3;

  data.title = data.title || {};
  data.title.text = isDefined(data.title.text)
    ? data.title.text
    : translate('pickAPresent.defaults.title');
  data.title.active = isDefined(data.title.active) ? data.title.active : true;

  data.subtitle = data.subtitle || {};
  data.subtitle.text = isDefined(data.subtitle.text)
    ? data.subtitle.text
    : translate('pickAPresent.defaults.subtitle');
  data.subtitle.active = isDefined(data.subtitle.active) ? data.subtitle.active : true;

  return data;
};

const initFontStyle = (name, style, global) => {
  style[name] = style[name] || {};

  if (name === 'title') {
    style[name].fontSize = style[name].fontSize || 16;
  }

  if (name === 'subtitle') {
    style[name].fontSize = style[name].fontSize || 13;
  }

  style[name].fontFamily = isDefined(style[name].fontFamily)
    ? style[name].fontFamily
    : global.canvas.fontFamily;
  style[name].fontSize = isDefined(style[name].fontSize)
    ? style[name].fontSize
    : global.text.fontSize;
  style[name].color = isDefined(style[name].color) ? style[name].color : global.text.color;
  style[name].textAlign = isDefined(style[name].textAlign) ? style[name].textAlign : 'center';
  style[name].lineHeight = isDefined(style[name].lineHeight) ? style[name].lineHeight : 1;
  style[name].fontWeight = isDefined(style[name].fontWeight) ? style[name].fontWeight : 400;
  style[name].fontItalic = isDefined(style[name].fontItalic) ? style[name].fontItalic : false;
  style[name].textDecoration = isDefined(style[name].textDecoration)
    ? style[name].textDecoration
    : false;
};

const initMargin = (style, global, name) => {
  if (global && !style.manualMargin) {
    style.manualMargin = _c(global);
  } else if (!style.manualMargin) {
    style.manualMargin = {};
    style.manualMargin.allSides = false;
    style.manualMargin.top = 0;
    style.manualMargin.left = 0;
    style.manualMargin.right = 0;
    style.manualMargin.bottom = 0;

    if (name === 'subtitle') {
      style.manualMargin.top = 20;
      style.manualMargin.bottom = 20;
    }
  }
};

const initPadding = (style, global) => {
  const noValue = !style.manualPadding || !style.manualPadding.top;
  if (global && !style.manualPadding) {
    style.manualPadding = _c(global);
  } else if (noValue) {
    style.manualPadding = {};
    style.manualPadding.allSides = true;
    style.manualPadding.top = 0;
    style.manualPadding.left = 0;
    style.manualPadding.right = 0;
    style.manualPadding.bottom = 0;
  }
};

const initOne = (key, style, global) => {
  initFontStyle(key, style, global);
  style[key].style = style[key].style || {};
  initMargin(style[key].style, undefined, key);
  initPadding(style[key].style, undefined);
};

const initStyle = (style, global) => {
  initMargin(style);
  initPadding(style, null);
  initOne('title', style, global);
  initOne('subtitle', style, global);

  style.icon = style.icon || {};
  style.icon.size = style.icon.size || 80;
  style.icon.horizontalSpacing = style.icon.horizontalSpacing || 35;

  return style;
};

export default (element, template) => {
  element.data = element.data || {};
  element.data = initData(element.data);
  element.style = element.style || {};
  element.style = initStyle(element.style, template.style);

  initMobileStyles(element, ['manualMargin']);

  element.style.mobile = nilMergeDeep(element.style.mobile, {
    icon: {
      size: null,
      horizontalSpacing: null,
    },
    title: {
      fontSize: null,
    },
    subtitle: {
      fontSize: null,
    },
  });

  return element;
};

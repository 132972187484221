import { get as _get, isEmpty } from 'lodash-es';
import { _isObject } from '@om/editor-ssr-shared/src/utils';
import { ELEMENTS } from '@om/template-properties/src/propertyHelper';
import { getBorderRadiusValueForElement } from '@om/template-properties/src/updateRadius';
import { IMAGE_POSITIONS } from '@/utils/color-components/helper';
import {
  cssFourValueResolve,
  campaignScope,
  BASE_FONT_SIZE,
  shadowOptions,
  textShadowOptions,
  findSelectedFamily,
} from '../util';
import { isDefined, getInlineSvgBackgroundUrl, isSvgUrl, isNano } from '../../../util';

export const MARGIN_TYPES = {
  outer: 'outer',
  element: 'element',
  overlay: 'overlay',
};

export const _font = (value) => {
  const font = findSelectedFamily(value);
  return font || null;
};

export const _pixelToEm = (value, localFontSize) => {
  const multiplier = localFontSize ? BASE_FONT_SIZE / localFontSize : 1;
  return (value / BASE_FONT_SIZE) * multiplier;
};

export const _emToPixel = (value, localFontSize) => {
  const multiplier = localFontSize ? BASE_FONT_SIZE / localFontSize : 1;
  return (value * BASE_FONT_SIZE) / multiplier;
};

export const _selector = (uid, type, margin = false, unique = null) => {
  const scoped = `${campaignScope} #${uid}`;

  if (unique && !_isObject(unique)) return unique;

  if (['OmCol', 'OmRow', 'OmDivider', 'OmPage'].includes(type)) {
    return scoped;
  }
  if (margin) {
    return `${campaignScope} [data-margin="${uid.replace('_', '_ma_')}"]`;
  }

  return scoped;
};

export const _color = (value, palette, fallback = '#fff') => {
  if (!palette) return value || fallback;
  if (palette[value]) {
    return `var(--om-color-${value})`;
  }
  return value || fallback;
};

export const _getTemplateColors = (template) => {
  let colors = [];
  if (template.palette) {
    colors = [...template.palette];
  }
  if (template.style?.palette?.themeColors && !isEmpty(template.style?.palette?.themeColors)) {
    colors += [...template.style.palette.themeColors.map(({ themeColor }) => themeColor)];
  }
  return colors;
};

const getBorderRoundingCss = (radius) => {
  const values = radius.map((v) => (v === null || v === '' ? '0px' : `${_pixelToEm(v)}em`));
  return { v: values.join(' ') };
};

const _generateCustomThemeRounding = (template, uid, elementType, mobile) => {
  const customThemeRoundingType = template?.themeKit?.rounding;
  let borderRadius = getBorderRadiusValueForElement(
    elementType,
    uid,
    customThemeRoundingType,
    template.elements,
    template.style.mode,
  );

  const everyValueNumber = borderRadius.every((value) => Number.isInteger(value));
  if (!everyValueNumber && borderRadius.length > 1) {
    borderRadius = mobile ? borderRadius[1].mobile : borderRadius[0].desktop;
  }
  return getBorderRoundingCss(borderRadius);
};

const _generateBorderRadiusCss = (template, uid, border, radius, mobile, type, rules) => {
  const isCustomThemeRounding = _get(border, 'customThemeRounding');
  const element = template.elements.find(({ uid: elId }) => elId === uid);
  if (element?.isTeaser) type = 'teaser';
  if (isCustomThemeRounding) {
    rules['border-radius'] = _generateCustomThemeRounding(template, uid, type, mobile);
  } else if (Array.isArray(radius) && radius.some(Boolean)) {
    let values = radius.map((v) => (v === null || v === '' ? '0px' : `${_pixelToEm(v)}em`));
    if (mobile) {
      const desktopRadius = _get(element, 'desktop.border.radius');
      values = radius.map((v, index) =>
        v === null || v === ''
          ? `${_pixelToEm(desktopRadius[index])}em` || '0px'
          : `${_pixelToEm(v)}em`,
      );
    }
    if (values.length) rules['border-radius'] = { v: values.join(' ') };
  }
};

const _generateBorderStyleCss = (
  template,
  selectedBorder,
  borderType,
  mobile,
  color,
  width,
  rules,
) => {
  if (selectedBorder && selectedBorder !== 'no') {
    if (borderType !== null) {
      rules['border-style'] = { v: borderType };
    }
    rules['border-color'] = {
      v: _color(color, _getTemplateColors(template)),
    };
    rules['border-width'] = { v: cssFourValueResolve(selectedBorder, _pixelToEm(width), 'em') };
  }
  if (mobile && selectedBorder === 'no') {
    rules.border = { v: 'none' };
  }
};

export const border = (uid, type, border, mobile, template, uniqueSelector) => {
  if (!border) return {};

  const { radius, selectedBorder, type: borderType, color, width } = border;
  const selector = _selector(uid, type, false, uniqueSelector);
  const rules = {};

  _generateBorderRadiusCss(template, uid, border, radius, mobile, type, rules);
  _generateBorderStyleCss(template, selectedBorder, borderType, mobile, color, width, rules);

  return Object.keys(rules).length ? { [selector]: rules } : {};
};

export const _fourValueProperty = (name, selector, styles, positions = []) => {
  if (!styles) return {};

  const rules = {};

  if (styles.allSides && positions && positions.length > 0) {
    rules[name] = { v: `${_pixelToEm(styles[positions[0]])}em` };
  } else if (styles.allSides && styles.top) {
    rules[name] = { v: `${_pixelToEm(styles.top)}em` };
  } else if (styles.left || styles.top || styles.right || styles.bottom) {
    const left = `${_pixelToEm(styles.left)}em`;
    const top = `${_pixelToEm(styles.top)}em`;
    const right = `${_pixelToEm(styles.right)}em`;
    const bottom = `${_pixelToEm(styles.bottom)}em`;
    rules[name] = { v: `${top} ${right} ${bottom} ${left}` };
  }

  return Object.keys(rules).length ? { [selector]: rules } : {};
};

export const _mobileFourValueProperty = (name, selector, styles) => {
  if (!styles) return {};

  const rules = {};
  Object.keys(styles).forEach((key) => {
    if (key === 'allSides' || styles[key] === null || key === name) return;
    const value = _pixelToEm(styles[key]);
    rules[`${name}-${key}`] = { v: `${value}em` };
  });

  return Object.keys(rules).length ? { [selector]: rules } : {};
};

export const padding = (uid, type, padding, mobile, template, uniqueSelector) => {
  const selector = _selector(uid, type, false, uniqueSelector);
  return mobile
    ? _mobileFourValueProperty('padding', selector, padding)
    : _fourValueProperty('padding', selector, padding);
};

export const margin = (
  uid,
  type,
  margin,
  mobile,
  template,
  uniqueSelector,
  marginSelector = true,
  positions,
) => {
  const selector = _selector(uid, type, marginSelector, uniqueSelector);

  return mobile
    ? _mobileFourValueProperty('margin', selector, margin)
    : _fourValueProperty('margin', selector, margin, positions);
};

export const shadow = (uid, type, shadow, mobile, template, uniqueSelector) => {
  if (!shadow) return;

  const { type: shadowType, color } = shadow;
  const selected = shadowOptions[shadowType];

  if (selected) {
    const selector = _selector(uid, type, false, uniqueSelector);
    const isPageOnRightSide = [3, 6, 9].includes(template.style.overlay.position);
    const value =
      shadowType === 'none'
        ? 'none'
        : `${type === 'OmPage' && isPageOnRightSide ? '-' : ''}${selected} ${_color(
            color,
            _getTemplateColors(template),
          )}`;

    return { [selector]: { 'box-shadow': { v: value } } };
  }

  return {};
};

export const textShadow = (uid, type, shadow, mobile, template, uniqueSelector) => {
  if (!shadow) return;

  const { type: shadowType, color } = shadow;
  const selected = textShadowOptions[shadowType];

  if (selected && selected !== 'none') {
    const selector = _selector(uid, type, false, uniqueSelector);
    const value = `${selected} ${_color(color, _getTemplateColors(template))}`;

    return { [selector]: { 'text-shadow': { v: value } } };
  }

  return {};
};

export const textRotation = (uid, type, textRotation, mobile, template, uniqueSelector) => {
  if (!textRotation) return;

  const selector = _selector(uid, type, false, uniqueSelector);

  return { [selector]: { transform: { v: `rotate(${textRotation}deg)` } } };
};

export const zIndex = (uid, type, zIndex, mobile, template, uniqueSelector) => {
  if (zIndex) {
    const selector = _selector(uid, type, true, uniqueSelector);

    return {
      [selector]: {
        position: { v: 'relative' },
        'z-index': { v: zIndex },
      },
    };
  }

  return {};
};

const backgroundImage = (
  { imagePosition, imageRepeat, imageHorizontalAlign, imageVerticalAlign, crop },
  imageUrl,
) => {
  const properties = {};

  properties.background = { v: `url('${imageUrl}')` };

  if (imageRepeat) {
    properties['background-repeat'] = { v: imageRepeat };
  }

  if (imagePosition) {
    if (imagePosition === IMAGE_POSITIONS.CROP && crop) {
      if (crop.url || crop.base64) {
        if (crop.base64) {
          // eslint-disable-next-line
          const SSRStore = typeof __vue_store__ !== 'undefined' ? __vue_store__ : null;
          if (SSRStore) {
            const { databaseId, variantId, type } = SSRStore.state;
            if (type === 'frontend' || type === 'preview-thumbnail') {
              console.log('Base64 found in cropped image', databaseId, variantId);
            }
          }
        }
        properties.background = { v: `url(${crop.url || crop.base64})` };
        properties['background-size'] = { v: IMAGE_POSITIONS.COVER };
      } else {
        const topPosition = _pixelToEm(Math.round(crop.cropBoxData.top - crop.canvasData.top));
        const leftPosition = _pixelToEm(Math.round(crop.cropBoxData.left - crop.canvasData.left));
        properties['background-size'] = { v: `${_pixelToEm(crop.canvasData.width)}em` };
        properties['background-position'] = {
          v: `-${leftPosition}em -${topPosition}em`,
        };
      }
    } else {
      if ([IMAGE_POSITIONS.CONTAIN, IMAGE_POSITIONS.COVER].includes(imagePosition)) {
        properties['background-size'] = { v: imagePosition };
        properties['background-position-x'] = { v: imageHorizontalAlign };
      }

      if (imagePosition === IMAGE_POSITIONS.CONTAIN) {
        properties['background-position-y'] = { v: imageVerticalAlign };
      } else if (imagePosition === IMAGE_POSITIONS.COVER) {
        properties['background-position-y'] = { v: imageVerticalAlign };
      } else if (imagePosition === IMAGE_POSITIONS.FULL) {
        properties['background-size'] = { v: 'auto auto' };
      } else {
        properties['background-size'] = { v: '100% 100%' };
      }
    }
  }

  return properties;
};

const getDropdownGradient = (colorObject, template) => {
  const startColor = _color(colorObject.color, _getTemplateColors(template));
  const endColor = _color(colorObject.color2, _getTemplateColors(template));
  const dropdownArrow = `url("data:image/svg+xml,%3Csvg id='arrow_down' data-name='arrow down' xmlns='http://www.w3.org/2000/svg' width='14' viewBox='0 0 20 12'%3E%3Cpath d='M15.88,3.22l-.6-.6A.38.38,0,0,0,15,2.5a.37.37,0,0,0-.27.12L10,7.35,5.27,2.62A.37.37,0,0,0,5,2.5a.38.38,0,0,0-.28.12l-.6.6A.38.38,0,0,0,4,3.5a.39.39,0,0,0,.12.28l5.6,5.6a.39.39,0,0,0,.56,0l5.6-5.6A.39.39,0,0,0,16,3.5.38.38,0,0,0,15.88,3.22Z'/%3E%3C/svg%3E")`;
  const deg = colorObject.linearDirection;

  return {
    'background-image': {
      v: `${dropdownArrow}, linear-gradient(${deg}deg,${startColor},${endColor})`,
      i: true,
    },
    'background-position': { v: '97%, 100%, 100%, 100%', i: true },
  };
};

const handleDropdownGradient = (background, template, selector) => {
  return {
    [selector]: getDropdownGradient(background, template),
  };
};

const findImageById = (imageId, images) => {
  const image = images.find((r) => r._id.toString() === imageId);
  return image;
};

const getBackgroundProperty = (colorObject, template, elementType) => {
  const { color, type: bgType } = colorObject;

  if (bgType === 'transparent') {
    return { background: { v: 'transparent' } };
  }

  if (bgType === 'solid-fill') {
    const startColor = _color(color, _getTemplateColors(template));
    return { background: { v: startColor } };
  }

  if (bgType === 'gradient') {
    const startColor = _color(color, _getTemplateColors(template));
    const isOmDropdown = elementType === ELEMENTS.OmDropdown;
    if (isOmDropdown) {
      return getDropdownGradient(colorObject, template);
    }

    const type = colorObject.gradientType;

    if (type === 'linear') {
      const deg = colorObject.linearDirection;
      const endColor = _color(colorObject.color2, _getTemplateColors(template));
      return { background: { v: `linear-gradient(${deg}deg,${startColor},${endColor})` } };
    }
  }

  if (bgType === 'image') {
    const image = findImageById(colorObject.imageId, template.images);

    if (image) {
      let backgroundUrl = image.url;

      if (isSvgUrl(image.url)) {
        if (template.inlineSvgs && template.inlineSvgs[image._id]) {
          backgroundUrl = getInlineSvgBackgroundUrl({
            svg: template.inlineSvgs[image._id],
            palette: template.palette,
          });
        }
      }

      return backgroundImage(colorObject, backgroundUrl);
    }
  }

  if (isDefined(color) && isDefined(bgType)) {
    return {
      'background-color': {
        v: _color(color, _getTemplateColors(template), 'transparent'),
      },
    };
  }

  return null;
};

export const background = (uid, type, background, mobile, template, uniqueSelector) => {
  const isOmDropdown = type === ELEMENTS.OmDropdown;
  if (!background) return {};

  const { color, type: bgType } = background;
  const startColor = _color(color, _getTemplateColors(template));

  const selector = _selector(uid, type, false, uniqueSelector);
  let rules = {};

  if (bgType === 'transparent') {
    return { [selector]: { background: { v: 'transparent' } } };
  }
  if (bgType === 'solid-fill') {
    return { [selector]: { background: { v: startColor } } };
  }
  if (bgType === 'gradient') {
    if (isOmDropdown) {
      return handleDropdownGradient(background, template, selector);
    }

    const type = background.gradientType;

    if (type === 'linear') {
      const deg = background.linearDirection;
      const endColor = _color(background.color2, _getTemplateColors(template));
      return {
        [selector]: {
          background: { v: `linear-gradient(${deg}deg,${startColor},${endColor})` },
        },
      };
    }
  } else if (bgType === 'image') {
    const image = findImageById(background.imageId, template.images);

    if (image) {
      let backgroundUrl = image.url;

      if (isSvgUrl(image.url)) {
        if (template.inlineSvgs && template.inlineSvgs[image._id]) {
          backgroundUrl = getInlineSvgBackgroundUrl({
            svg: template.inlineSvgs[image._id],
            palette: template.palette,
          });
        }
      }

      rules = { ...rules, ...backgroundImage(background, backgroundUrl) };
    }
  }

  if (isDefined(color) && isDefined(bgType)) {
    rules['background-color'] = {
      v: _color(color, _getTemplateColors(template), 'transparent'),
    };
  }

  return { [selector]: rules };
};

export const overflow = (uid, type, overflow, mobile, template, uniqueSelector) => {
  if (overflow === 'hidden') {
    const selector = _selector(uid, type, false, uniqueSelector);
    return { [selector]: { overflow: { v: 'hidden' } } };
  }

  return {};
};

const allowedZeroValueProps = ['border-radius'];
export const _simpleEmTransform = (uid, type, value, prop, uniqueSelector, important) => {
  if (!value && !allowedZeroValueProps.includes(prop)) return {};

  const selector = _selector(uid, type, false, uniqueSelector);

  return { [selector]: { [prop]: { v: `${_pixelToEm(value)}em`, i: important } } };
};
export const _simpleTransformWithPixel = (uid, type, value, prop, uniqueSelector, important) => {
  if (!value && !allowedZeroValueProps.includes(prop)) return {};

  const selector = _selector(uid, type, false, uniqueSelector);

  return { [selector]: { [prop]: { v: `${value}px`, i: important } } };
};

export const minHeight = (uid, type, minHeight, mobile, template, uniqueSelector) =>
  _simpleEmTransform(uid, type, minHeight, 'min-height', uniqueSelector);
export const height = (uid, type, height, mobile, template, uniqueSelector) =>
  _simpleEmTransform(uid, type, height, 'height', uniqueSelector);
export const width = (uid, type, width, mobile, template, uniqueSelector) =>
  _simpleEmTransform(uid, type, width, 'width', uniqueSelector);
export const smartSize = (uid, type, size, mobile, template, uniqueSelector, em = true) => {
  const selector = _selector(uid, type, false, uniqueSelector);

  const rules = {};
  if (size.type === '100w') {
    rules.width = { v: '100%' };
  } else if (size.type === 'fluid') {
    rules.width = { v: 'auto' };
  } else if (size.type === 'manual' && isDefined(size.width)) {
    rules.width = { v: em ? `${_pixelToEm(size.width)}em` : `${size.width}px` };
  }

  if (size.height) {
    if (isNano(template.style.mode)) {
      rules.height = { v: '100%' };
      rules['max-height'] = { v: `${_pixelToEm(size.height)}em` };
    } else {
      rules.height = { v: `${_pixelToEm(size.height)}em`, i: true };
    }
  }

  return { [selector]: rules };
};
export const maxWidth = (uid, type, maxWidth, mobile, template, uniqueSelector) =>
  _simpleEmTransform(uid, type, maxWidth, 'max-width', uniqueSelector);
export const fontMultiplier = (uid, type, multiplier, mobile, template, uniqueSelector) => {
  const selector = _selector(uid, type, false, uniqueSelector);
  const calculated = multiplier / 100;

  if (calculated === 0) {
    return {};
  }

  return { [selector]: { 'font-size': { v: `${calculated}em`, i: true } } };
};
export const fontSize = (uid, type, size, mobile, template, uniqueSelector) =>
  _simpleEmTransform(uid, type, size, 'font-size', uniqueSelector);
export const fontSizeWithPixel = (uid, type, size, mobile, template, uniqueSelector) =>
  _simpleTransformWithPixel(uid, type, size, 'font-size', uniqueSelector);
export const borderRadius = (uid, type, value, mobile, template, uniqueSelector) =>
  _simpleEmTransform(uid, type, value, 'border-radius', uniqueSelector);

const makePropsImportant = (properties) => {
  const props = { ...properties };

  Object.keys(properties).forEach((property) => {
    props[property].i = true;
  });

  return props;
};

export const hover = (uid, type, hover, mobile, template, uniqueSelector) => {
  if (!hover) return;

  const {
    backgroundColor,
    fontColor,
    color,
    imageUrl,
    type: hoverType,
    borderColor,
    background: hoverBackground,
  } = hover;

  const selector = `${_selector(uid, type, false, uniqueSelector)}:hover`;
  let properties = {};

  if (fontColor) {
    properties.color = {
      v: _color(fontColor, _getTemplateColors(template), template.palette[0]),
      i: true,
    };
  }

  if (color && hoverType !== 'image') {
    const color = Object.values(background(uid, type, hover, mobile, template, uniqueSelector))[0];
    properties.background = color.background;
  } else if (backgroundColor && type !== 'OmProduct') {
    properties.background = {
      v: _color(backgroundColor, _getTemplateColors(template), template.palette[0]),
      i: true,
    };
  } else if (hoverBackground?.type) {
    const hoverBgProps = makePropsImportant(getBackgroundProperty(hoverBackground, template, type));

    properties = {
      ...properties,
      ...hoverBgProps,
    };
  }

  if (hoverType === 'image') {
    properties = { ...properties, ...backgroundImage(hover, imageUrl) };
  }

  if (borderColor) {
    properties['border-color'] = {
      v: _color(borderColor, _getTemplateColors(template), template.palette[0]),
      i: true,
    };
  }

  return { [selector]: properties };
};

export const fontFamily = (uid, type, fontFamily, mobile, template, uniqueSelector) => {
  if (!fontFamily) return {};

  const selector = _selector(uid, type, false, uniqueSelector);
  const v = _font(fontFamily);

  if (!v) return {};

  return { [selector]: { 'font-family': { v } } };
};

export const _simpleValue = (uid, type, v, prop, uniqueSelector) => {
  if (!v) return {};

  const selector = _selector(uid, type, false, uniqueSelector);

  return { [selector]: { [prop]: { v } } };
};

export const textAlign = (uid, type, textAlign, mobile, template, uniqueSelector) =>
  _simpleValue(uid, type, textAlign, 'text-align', uniqueSelector);
export const color = (uid, type, color, mobile, template, uniqueSelector) => {
  return _simpleValue(
    uid,
    type,
    _color(color, _getTemplateColors(template)),
    'color',
    uniqueSelector,
  );
};

export const fontItalic = (uid, type, isItalic, mobile, template, uniqueSelector) =>
  _simpleValue(uid, type, isItalic ? 'italic' : 'unset', 'font-style', uniqueSelector);
export const textWeight = (uid, type, isBold, mobile, template, uniqueSelector) =>
  _simpleValue(uid, type, isBold ? 'bold' : 'unset', 'font-weight', uniqueSelector);
export const fontWeight = (uid, type, fontWeight, mobile, template, uniqueSelector) =>
  _simpleValue(uid, type, fontWeight, 'font-weight', uniqueSelector);
export const textDecoration = (uid, type, isUnderlined, mobile, template, uniqueSelector) =>
  _simpleValue(uid, type, isUnderlined ? 'underline' : false, 'text-decoration', uniqueSelector);
export const textStrikeThrough = (uid, type, isStrikedThrough, mobile, template, uniqueSelector) =>
  _simpleValue(
    uid,
    type,
    isStrikedThrough ? 'line-through' : false,
    'text-decoration',
    uniqueSelector,
  );
export const lineHeight = (uid, type, lineHeight, mobile, template, uniqueSelector) =>
  _simpleValue(uid, type, lineHeight, 'line-height', uniqueSelector);

export const font = (uid, type, elementStyle, mobile, template, uniqueSelector) => {
  return [
    fontFamily(uid, type, elementStyle.fontFamily, mobile, template, uniqueSelector),
    fontSize(uid, type, elementStyle.fontSize, mobile, template, uniqueSelector),
    color(uid, type, elementStyle.color, mobile, template, uniqueSelector),
    textAlign(uid, type, elementStyle.textAlign, mobile, template, uniqueSelector),
    textWeight(uid, type, elementStyle.textWeight, mobile, template, uniqueSelector),
    fontWeight(uid, type, elementStyle.fontWeight, mobile, template, uniqueSelector),
    fontItalic(uid, type, elementStyle.fontItalic, mobile, template, uniqueSelector),
    textDecoration(uid, type, elementStyle.textDecoration, mobile, template, uniqueSelector),
    lineHeight(uid, type, elementStyle.lineHeight, mobile, template, uniqueSelector),
  ];
};

export const left = (uid, type, value, mobile, template, uniqueSelector) =>
  _simpleEmTransform(uid, type, value, 'left', uniqueSelector);
export const right = (uid, type, value, mobile, template, uniqueSelector) =>
  _simpleEmTransform(uid, type, value, 'right', uniqueSelector);
export const top = (uid, type, value, mobile, template, uniqueSelector) =>
  _simpleEmTransform(uid, type, value, 'top', uniqueSelector);
export const bottom = (uid, type, value, mobile, template, uniqueSelector) =>
  _simpleEmTransform(uid, type, value, 'bottom', uniqueSelector);

export const alignSelf = (uid, type, value, mobile, template, uniqueSelector) =>
  _simpleValue(uid, type, value, 'align-self', uniqueSelector);
export const verticalAlign = (uid, type, value, mobile, template, uniqueSelector) =>
  _simpleValue(uid, type, value, 'align-items', uniqueSelector);
export const alignContent = (uid, type, value, mobile, template, uniqueSelector) =>
  _simpleValue(uid, type, value, 'align-content', uniqueSelector);
export const justifyContent = (
  uid,
  type,
  value,
  mobile,
  template,
  uniqueSelector,
  addDisplay = false,
) => {
  if (!value) return {};

  const selector = _selector(uid, type, false, uniqueSelector);
  const rules = {
    'justify-content': { v: value },
  };

  if (addDisplay) {
    rules.display = { v: 'flex' };
  }

  return {
    [selector]: rules,
  };
};
export const alignItems = (
  uid,
  type,
  value,
  mobile,
  template,
  uniqueSelector,
  addDisplay = false,
) => {
  if (!value) return {};

  const selector = _selector(uid, type, false, uniqueSelector);
  const rules = {
    'align-items': { v: value },
  };

  if (addDisplay) {
    rules.display = { v: 'flex' };
  }

  return {
    [selector]: rules,
  };
};
export const horizontalAlign = justifyContent;

export const orientation = (uid, type, value) => {
  if (value != null) {
    return {
      [_selector(uid, type)]: {
        display: {
          v: 'flex',
        },
        'flex-direction': {
          v: value ? 'column' : 'row',
        },
      },
    };
  }
};

export const join = (properties) => {
  const props = [];

  for (const property in properties) {
    const value = properties[property];
    props.push(`${property}:${value?.v}${value?.i ? '!important' : ''};`);
  }

  return props.join('\n');
};

export { campaignScope, BASE_FONT_SIZE };

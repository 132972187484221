import { translate, isDefined } from '../../../utils';
import { initMobileStyles } from '../util';

const _c = (v) => JSON.parse(JSON.stringify(v));

const initData = (data) => {
  data.layout = isDefined(data.layout) ? data.layout : 'vertical';
  data.align = data.align || 'center';
  data.url = data.url || '';
  const connected = data.shopConnected ? 'connected' : 'notConnected';
  data.ctaText = data.ctaText || translate(`productTexts.example.cta.${connected}`);
  data.product = data.product || false;

  data.image = data.image || {};
  data.image.imageId = data.image.imageId || null;
  data.image.imageUrl = data.image.imageUrl || null;
  data.image.active = isDefined(data.image.active) ? data.image.active : true;

  data.name = data.name || {};
  data.name.text = isDefined(data.name.text)
    ? data.name.text
    : translate('productTexts.example.name');
  data.name.active = isDefined(data.name.active) ? data.name.active : true;

  data.sku = data.sku || {};
  data.sku.text = isDefined(data.sku.text) ? data.sku.text : translate('productTexts.example.sku');
  data.sku.active = isDefined(data.sku.active) ? data.sku.active : false;

  data.price = data.price || {};
  data.price.text = isDefined(data.price.text)
    ? data.price.text
    : translate('productTexts.example.price.new');
  data.price.active = isDefined(data.price.active) ? data.price.active : true;

  data.oldPrice = data.oldPrice || {};
  data.oldPrice.text = isDefined(data.oldPrice.text)
    ? data.oldPrice.text
    : translate('productTexts.example.price.old');
  data.oldPrice.active = isDefined(data.oldPrice.active) ? data.oldPrice.active : true;

  return data;
};

const initFontStyle = (name, style, global, button = false) => {
  style[name] = style[name] || {};

  if (name === 'name') {
    style[name].fontStyle = style[name].fontStyle || { textWeight: true };
    style[name].fontSize = style[name].fontSize || 16;
  }

  if (name === 'sku') {
    style[name].fontSize = style[name].fontSize || 13;
  }

  if (name === 'price') {
    style[name].fontStyle = style[name].fontStyle || { textWeight: true };
    style[name].fontSize = style[name].fontSize || 16;
  }

  if (name === 'oldPrice') {
    style[name].fontStyle = style[name].fontStyle || { textStrikeThrough: true };
    style[name].fontSize = style[name].fontSize || 13;
    style[name].color = style[name].color || 'rgb(181, 181, 181)';
  }

  if (button) {
    style[name].fontFamily = isDefined(style[name].fontFamily)
      ? style[name].fontFamily
      : global.button.fontFamily || global.canvas.fontFamily;
    style[name].fontSize = isDefined(style[name].fontSize)
      ? style[name].fontSize
      : parseInt(global.button.defaultFontSize, 10);
    style[name].fontStyle = isDefined(style[name].fontStyle)
      ? style[name].fontStyle
      : {
          textWeight: global.button.textWeight,
        };
    style[name].color = isDefined(style[name].color) ? style[name].color : global.button.color;
    style[name].textAlign = isDefined(style[name].textAlign) ? style[name].textAlign : 'center';
    return;
  }

  style[name].fontFamily = isDefined(style[name].fontFamily)
    ? style[name].fontFamily
    : global.canvas.fontFamily;
  style[name].fontSize = isDefined(style[name].fontSize)
    ? style[name].fontSize
    : global.text.fontSize;
  style[name].color = isDefined(style[name].color) ? style[name].color : global.text.color;
  style[name].textAlign = isDefined(style[name].textAlign) ? style[name].textAlign : 'left';
};

const initBackground = (style, global) => {
  if (global && !style.background) {
    style.background = _c(global);
  } else if (!style.background || !style.background.color) {
    style.background = {};
    style.background.color = -1;
    style.background.color2 = '#fff';
    style.background.type = 'transparent';
    style.background.gradientType = 'linear';
    style.background.linearDirection = 45;
    style.background.imagePosition = 'cover';
    style.background.imageAlign = 'center';
    style.background.imageHorizontalAlign = 'center';
    style.background.imageRepeat = 'no-repeat';
  }
};

const initBorderAndShadow = (style, global) => {
  if (global && !style.shadow) {
    style.shadow = _c(global.shadow);
  } else if (!style.shadow || !style.shadow.type) {
    style.shadow = {};
    style.shadow.type = 'none';
    style.shadow.color = '#000';
  }

  if (global && !style.border) {
    style.border = _c(global.border);
  } else if (!style.border || !style.border.selectedBorder) {
    style.border = {};
    style.border.selectedBorder = 'no';
    style.border.type = 'solid';
    style.border.color = 0;
    style.border.width = 2;
    style.border.radius = [null, null, null, null];
    style.border.radiusChained = true;
  }
};

const initMargin = (style, global, name) => {
  if (global && !style.manualMargin) {
    style.manualMargin = _c(global);
  } else if (!style.manualMargin) {
    style.manualMargin = {};
    style.manualMargin.allSides = false;
    style.manualMargin.top = 0;
    style.manualMargin.left = 0;
    style.manualMargin.right = 0;
    style.manualMargin.bottom = 0;

    if (name === 'name') {
      style.manualMargin.bottom = 5;
    }

    if (name === 'sku') {
      style.manualMargin.bottom = 10;
    }

    if (name === 'price' || name === 'oldPrice') {
      style.manualMargin.bottom = 15;
    }

    if (name === 'oldPrice') {
      style.manualMargin.right = 10;
    }
  }
};

const initPadding = (style, global, button, card) => {
  const noValue = !style.manualPadding || !style.manualPadding.top;
  if (global && !style.manualPadding) {
    style.manualPadding = _c(global);
  } else if (noValue) {
    style.manualPadding = {};
    style.manualPadding.allSides = true;
    style.manualPadding.top = 0;
    style.manualPadding.left = 0;
    style.manualPadding.right = 0;
    style.manualPadding.bottom = 0;
  }

  if (noValue && button) {
    style.manualPadding.allSides = true;
    style.manualPadding.top = 15;
    style.manualPadding.left = 15;
    style.manualPadding.right = 15;
    style.manualPadding.bottom = 15;
  }

  if (noValue && card) {
    style.manualPadding.allSides = true;
    style.manualPadding.top = 0;
    style.manualPadding.left = 0;
    style.manualPadding.right = 0;
    style.manualPadding.bottom = 0;
  }
};

const initHover = (style, global) => {
  if (global && !style.hover) {
    style.hover = _c(global);
  }
};

const initSize = (style, global) => {
  style.size = isDefined(style.size) ? style.size : 'fluid';
  style.width = isDefined(style.width) ? style.width : global.globalWidth;
  style.height = isDefined(style.height) ? style.height : global.globalHeight;
};

const initButtonStyle = (style, global) => {
  initBackground(style, global.background);
  initBorderAndShadow(style, {
    border: global.border,
    shadow: global.shadow,
  });
  initMargin(style, global.manualMargin);
  initPadding(style, global.manualPadding, true);
  initHover(style, global.hover);
  initSize(style, global);
};

const initOne = (key, style, global) => {
  initFontStyle(key, style, global);
  style[key].style = style[key].style || {};
  initMargin(style[key].style, undefined, key);
  initPadding(style[key].style, undefined);
};

const initStyle = (style, global) => {
  initBackground(style);
  initBorderAndShadow(style);
  initMargin(style);
  initPadding(style, null, false, true);
  initOne('image', style, global);
  initBorderAndShadow(style.image.style);
  initOne('name', style, global);
  initOne('sku', style, global);
  initOne('price', style, global);
  initOne('oldPrice', style, global);
  style.button = style.button || {};
  style.button.style = style.button.style || {};
  initFontStyle('style', style.button, global, true);
  initButtonStyle(style.button.style, global.button);
  style.image = style.image || {};
  style.image.height = style.image.height || 220;
  return style;
};

export default (element, template) => {
  element.data = element.data || {};
  element.data = initData(element.data);
  element.style = element.style || {};
  element.style = initStyle(element.style, template.style);

  initMobileStyles(element, ['manualMargin', 'manualPadding']);

  return element;
};

<template lang="pug">
.w-100
  .template-prev-container.flex-row.row
    .desktop-preview.col
      preview-frame(
        :load="!bigPreviewLoaded"
        :selectedPageUid="selectedPageUid"
        device="desktop"
        :template="currentTemplate"
        :color="color"
        :themeKit="themeKit"
        @loadThumbnails="bigPreviewLoaded = true"
        :isAdmin="isAdmin"
      )
    .mobile-preview.col-auto
      preview-frame(
        :load="!bigPreviewLoaded"
        :selectedPageUid="selectedPageUid"
        device="mobile"
        :template="currentTemplate"
        :color="color"
        :themeKit="themeKit"
        @loadThumbnails="bigPreviewLoaded = true"
        :isAdmin="isAdmin"
      )
  .w-100
    page-previews(
      :selectedPageUid="selectedPageUid"
      :pages="pages"
      :bigPreviewLoaded="bigPreviewLoaded"
      :template="template"
      :color="color"
      :themeKit="themeKit"
      :isAdmin="isAdmin"
      @page-change="handlePageChange"
    )
</template>
<script>
  import PreviewFrame from '@/components/Modals/TemplatePreview/PreviewFrame';
  import PagePreviews from '@/components/Modals/TemplatePreview/PagePreviews.vue';

  export default {
    components: { PreviewFrame, PagePreviews },
    props: {
      device: {
        type: String,
        default: 'desktop',
      },
      color: {
        type: String,
      },
      template: {
        type: Object,
        default: () => null,
      },
      themeKit: {
        type: Object,
        default: () => null,
      },
      isAdmin: {
        type: Boolean,
        default: false,
      },
    },
    data() {
      return {
        parsedTemplate: {},
        pages: [],
        selectedPageUid: null,
        bigPreviewLoaded: false,
        bigPreviewTimeout: null,
        resizeTimeout: null,
      };
    },
    computed: {
      currentTemplate() {
        if (!this.resizeTimeout) return this.template;
        return null;
      },
      teaserPage() {
        return this.pages.find((page) => page.isTeaser);
      },
      normalPages() {
        return this.pages.filter((page) => !page.isTeaser);
      },
      isEmbedded() {
        return this.template?.type === 'embedded';
      },
      isUniversal() {
        return !!this.template?.universal;
      },
    },
    watch: {
      template: {
        handler(template) {
          this.initPreviews(template);
        },
        deep: true,
      },
      parsedTemplate: {
        handler(v) {
          if (v.elements) {
            this.pages = v.elements.filter((el) => el.type === 'OmPage');
          } else if (v.pages) {
            this.pages = v.pages;
          }
        },
        deep: true,
      },
      pages: {
        handler(v) {
          v.forEach(
            (p, index) => this.pageInitializer(p, index, this.pages.length),
            this.parsedTemplate,
          );

          try {
            this.selectedPageUid = v[0].uid;
          } catch (e) {
            console.error('Template does not have pages', v[0].uid);
          }
        },
      },
      bigPreviewLoaded(value) {
        if (value && this.bigPreviewTimeout) clearTimeout(this.bigPreviewTimeout);
      },
    },
    mounted() {
      if (this.template) {
        this.initPreviews(this.template);
      } else {
        this.setBigPreviewLoadTimeout();
      }
    },
    methods: {
      init() {
        if (this.resizeTimeout) return;

        this.resizeTimeout = setTimeout(() => {
          this.resizeTimeout = null;
        }, 0);
      },
      initPreviews(template) {
        if (!template?.template) {
          return;
        }
        this.bigPreviewLoaded = false;
        this.parseTemplate();
        this.setBigPreviewLoadTimeout();
      },
      handlePageChange(uid) {
        this.selectedPageUid = uid;
      },
      setBigPreviewLoadTimeout() {
        this.bigPreviewTimeout = setTimeout(() => {
          this.bigPreviewLoaded = true;
        }, 10000);
      },
      parseTemplate() {
        this.parsedTemplate = JSON.parse(this.template.template);
      },
      pageInitializer(page, index, pagesLength) {
        page.data = page.data || {};

        if (page.isTeaser) {
          page.data.title = 'Teaser';
          return;
        }

        let title = `${this.$t('page')} ${index}`;

        if (index === 0) {
          title = this.$t('mainPage');
        } else if (index === pagesLength - 1) {
          title = this.$t('thankYou');
        }

        page.data.title = page.data.title || title;
      },
    },
  };
</script>
<style lang="sass">
  @import "@/sass/helpers/media-breakpoint"

  .template-prev
    &-container
      .template-prev-holder
        height: 30rem
        max-width: 920px
      .mobile-preview
        flex: 0 0 15rem
        max-width: 15rem
    &-embedded
      height: 100%
    &-holder,
    &-separator,
    &-title
      grid-column: span 1
      grid-row: span 1
    &-title-teaser
      grid-column: span 2
    &-page
      &-previews
        display: flex
        margin-top: 1rem
        &.overflow-scrolling-touch
          @include media-breakpoint-down(sm)
            justify-content: flex-start
            overflow-x: scroll
            overflow-scrolling: touch
            flex-wrap: nowrap
            width: auto
      &-preview
        display: grid
        grid-template-columns: auto auto
        grid-gap: 9px
        margin-right: 9px
        border-radius: 4px
        text-align: center
        cursor: pointer
        font-size: 0.6875rem
        line-height: 1.5rem
        color: #505763
        font-weight: bold
        .template-prev
          box-shadow: 0 0 0 1px #F1F2F4
          border-radius: 4px
          overflow: hidden
        &-selected
          color: #ED5A29
          .template-prev
            box-shadow: 0 0 0 2px #ED5A29
          &:hover
            .template-prev
              box-shadow: 0 0 0 2px #F17E58
            .template-prev-title
              color: #F17E58
        &-teaser
          grid-template-columns: auto
          grid-gap: 9px 0
          margin-right: 0
        &:last-child
          grid-template-columns: auto
          grid-gap: 9px 0
          margin-right: 0
          .template-prev-title
            grid-column: span 2
        &:hover
          color: #8F97A4
          .template-prev-holder
            box-shadow: 0 8px 20px rgba(0, 0, 0, 0.1), 0 0 32px rgba(0, 0, 0, 0.04)
  .template-prev-page-previews
    .template-prev-holder-small
      .template-prev-embedded
        .om-mock-main
          padding: 0.75em 0
          background: #E9EFF4
          overflow: hidden
      .template-prev-mobile-frame
        .om-mock-main
          padding: 1em 0
          .om-mock-col
            padding: 0.5em 1px
    .template-prev-mobile-frame
      .om-mock-body
        height: 100%
        width: 100%
        left: 0
        top: 0
        border-radius: 3px
        padding: 0
      .om-mock-header
        height: 100%!important
        margin-bottom: 0!important
        border-radius: 3px 3px 0 0!important
        min-height: unset
      .om-mock-main
        padding: 2.2em 0
        height: auto
        .om-mock-col
          position: relative
          overflow: hidden
          height: 100%
          background: #fff
          padding: 0.5em 0
          border-radius: 0
          iframe
            position: static!important
      .om-mock-footer
        height: 100%!important
        margin-top: 0!important
        border-radius: 0 0 3px 3px!important
        min-height: unset
    .template-prev-mobile-frame
      .om-mock-body
          height: calc(100% - 10px)
          width: calc(100% - 4px)
          left: 2px
          top: 4px
      .om-mock-main
        padding: 1em 0
        .om-mock-col
          padding: 0.5em 1px
    .template-prev-holder-small
      .template-prev-embedded
        .om-mock-main
          padding: unset
          height: 100%
          overflow: hidden
</style>

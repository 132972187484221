export default {
  methods: {
    getTemplateType(template) {
      if (template.seasonal) {
        return 'published';
      }
      return template.status;
    },
  },
};

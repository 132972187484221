import { get as _get } from 'lodash-es';
import { initWithDefaults } from '../utils';
import { _migrateBorderRadius } from '../post/base';

export const baseHoverColors = {
  fontColor: '#fff',
  borderColor: 4,
  backgroundColor: 4,
};

export const baseBackgroundProps = (color) => {
  return {
    color,
    color2: '#fff',
    type: 'solid-fill',
    gradientType: 'linear',
    gradientCenter: null,
    linearDirection: 45,
    imageId: null,
    imageAlign: 'center',
    imagePosition: 'cover',
    imageHorizontalAlign: 'center',
    imageVerticalAlign: 'center',
    imageRepeat: 'no-repeat',
  };
};

const background = (value, overrides) => {
  const color = _get(value, 'type') === 'image' ? null : 0;

  return initWithDefaults(value, {
    ...baseBackgroundProps(color),
    ...overrides,
  });
};

const border = (value, overrides) => {
  _migrateBorderRadius(value);
  return initWithDefaults(value, {
    radius: [5, 5, 5, 5],
    selectedBorder: 'no',
    type: 'solid',
    color: 1,
    width: 2,
    radiusChained: false,
    customThemeRounding: false,
    ...overrides,
  });
};

const shadow = (value, overrides) =>
  initWithDefaults(value, {
    type: 'none',
    color: '#000',
    ...overrides,
  });

const textShadow = (value, overrides) =>
  initWithDefaults(value, {
    type: 'none',
    color: '#000',
    ...overrides,
  });

const padding = (value, overrides) =>
  initWithDefaults(value, {
    allSides: true,
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    ...overrides,
  });

const nullPadding = (value, overrides) =>
  initWithDefaults(value, {
    allSides: true,
    left: null,
    right: null,
    top: null,
    bottom: null,
    ...overrides,
  });

const margin = (value, overrides) =>
  initWithDefaults(value, {
    allSides: true,
    margin: 0,
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    ...overrides,
  });

const nullMargin = (value, overrides) =>
  initWithDefaults(value, {
    allSides: true,
    margin: null,
    left: null,
    right: null,
    top: null,
    bottom: null,
    ...overrides,
  });

const buttonHover = (value, overrides) =>
  initWithDefaults(value, {
    ...baseHoverColors,
    ...overrides,
  });

export default {
  background,
  border,
  shadow,
  textShadow,
  padding,
  nullPadding,
  margin,
  nullMargin,
  buttonHover,
};
